import { useEffect, useState, lazy, Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Cookies from 'js-cookie';
import InitialLoading from '../../pages/initial_loader/InitialLoading';

// Lazy load the Header and Nav components as they contain some data that has to be initialised after the setting of cookie
const LazyHeader = lazy(() => import('./header'));
const LazyNav = lazy(() => import('./nav'));

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  // verifying the login here
  const [moveFurther, setMoveFurther] = useState(false);

  const validateLogin = () => {
    if (Cookies.get('user_data_rejoice_solutions') !== undefined) {
      setMoveFurther(true);
      // redirect ahead
    } else {
      // redirect to login
      navigate('/login');
    }
  }



  useEffect(() => {
    validateLogin();
  })

  return moveFurther === false ? (<InitialLoading />) :
    (<StyledRoot>
      <Suspense fallback={<InitialLoading />}>
        <LazyHeader onOpenNav={() => setOpen(true)} />
        <LazyNav openNav={open} onCloseNav={() => setOpen(false)} />
      </Suspense>

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
    );
}
