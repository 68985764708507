import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoData = () => {
    return (
        <Box
            sx={{
                mb: 3,
                textAlign: 'center'
            }}
        >
            <img
                alt="Registration Successful"
                src="/assets/graphics/no-data.avif"
                style={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    width: 450
                }}
            />
            <Typography
                align="center"
                color="text.secondary"
                variant="body1"
            >
                Nothing to Display. Create a new record to continue.
            </Typography>
        </Box>
    );
}

export default NoData;