import { formatPhoneNumberIntl } from "react-phone-number-input"

export const displayMobileNumber = (x) => {
    return <>
        <p>
            {formatPhoneNumberIntl(x)}
        </p>
    </>;

}

export const getDateYmd = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const localDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = localDate.split('/');
    return `${year}-${month}-${day}`;
}

export const getDatedmY = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const localDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = localDate.split('/');
    return `${day}-${month}-${year}`;
}