import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { useNavigate } from "react-router-dom"
// import Router from "../../routes"
// import { Link } from "react-router-dom"

const BreadCrumbs = ({ path }) => {
    // const navigate = useNavigate()
    return (
        <Box m={2}>
            <Breadcrumbs aria-label="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                <Link href="/dashboard/home" color={"inherit"} sx={{ textDecoration: 'none' }}>Home</Link>
                {path === "users" ? (<Link href="/dashboard/users" color={"inherit"} sx={{ textDecoration: 'none' }}>Users</Link>) : ("")}
                {path === "products" ? (<Link href="/dashboard/products" color={"inherit"} disabled={path} sx={{ textDecoration: 'none' }}>Products</Link>
                ) : ("")}
                {path === "companies" ? (<Link href="/dashboard/companies" color={"inherit"} sx={{ textDecoration: 'none' }}>Companies</Link>

                ) : ("")}
                {path === "branches" ? (<Link href="/dashboard/branches" color={"inherit"} sx={{ textDecoration: 'none' }}>Branches</Link>) : ("")}
                {/* {path === "Products" ? (<Link to="/dashboard/products">Products</Link>
                ) : ("")} */}

                {/* <Link to="/dashboard/users">Users</Link> */}
            </Breadcrumbs>
        </Box>
    )
}

export default BreadCrumbs




// import { ChevronRight } from '@mui/icons-material';
// // import { Breadcrumbs, Typography } from '@mui/material';
// // import Link from 'next/link';
// // import { useRouter } from 'next/router';
// // import { pathName } from 'src/variables/path-variables';
// // import { findMissingKeys, removeKeys } from '../../functions/data-functions';

// const BreadCrumbs = () => {
//     const router = useRouter();
//     // Get the current pathname
//     const { pathname } = router;

//     // Find the breadcrumb object for the current pathname
//     const breadcrumb = Object.entries(pathName).find(([path]) => path === pathname);
//     // If no breadcrumb object was found, return null
//     if (!breadcrumb) {
//         return null;
//     }

//     const [path, { title, paramsToKeep, hierarchy = [] }] = breadcrumb;

//     // Remove query parameters from the URL based on the paramsToKeep array
//     const removeQueryParams = (url, paramsToKeep) => {
//         const paramsToRemove = findMissingKeys(router.query, paramsToKeep);
//         const updatedQueryParams = new URLSearchParams(removeKeys(router.query, paramsToRemove)).toString();
//         return updatedQueryParams;
//     };

//     // Create an array of breadcrumb items for the hierarchy
//     const breadcrumbItems = hierarchy.map((path) => ({
//         label: pathName[path].title,
//         url: path,
//         paramsToKeep: pathName[path].paramsToKeep
//     }));

//     // Add the current breadcrumb item
//     breadcrumbItems.push({ label: title, url: path, paramsToKeep: [] });

//     return (
//         <Breadcrumbs separator={<ChevronRight fontSize="small" />}
//             aria-label="breadcrumb">
//             {breadcrumbItems.map((item, index) => {
//                 const isLastItem = index === breadcrumbItems.length - 1;
//                 const linkOrText = isLastItem ? (
//                     <Typography  key={index} color="primary" sx={{ fontSize: '11pt' }}>{item.label}</Typography>
//                 ) : (
//                     <Link key={index} href={{ pathname: item.url, query: removeQueryParams(item.url, item.paramsToKeep) }} style={{
//                         textDecoration: 'double',
//                         fontSize:'11pt',
//                         color: 'gray'
//                     }} >
//                             {item.label}
//                     </Link>
//                 );
//                 return linkOrText;
//             })}
//         </Breadcrumbs >
//     );
// };

// export default BreadCrumbs;