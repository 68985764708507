import {
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useMemo, useState } from 'react';
import { Edit, RemoveRedEye } from '@mui/icons-material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useHandleClick } from '../../components/list/use-handle-click';
import { API_SIGN, FETCH_BRANCH } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { decryptToken } from '../../components/security/securityFunctions';
import NoData from '../../components/no_data/NoData';
import { ShallowRouting } from '../../components/routing/ShallowRouting';
import './displayBranch.css'

const DisplayBranch = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
  // const [companyId, setCompanyId] = useState(urlSearchParams.get('company_id') || null);
  const [searchString, setSearchString] = useState('');

  const [showLoader, setShowLoader] = useState(true);
  const [rows, setRowsChange] = useState([]);
  const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0);;
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [noData, setNoData] = useState(false)
  const [totalRows, setTotalRows] = useState(0);


  const handlePageChange = (e, value) => {
    console.log(value)
    const existingUrlSearchParams = urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => { return key === "" ? value : decodeURIComponent(value) }) : null;
    setPage(value);
    navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
    fetchBranches(null, null, value?.toString());
    // setPage(value);
  };


  const handleRowsPerPageChange =
    (value) => {
      console.log(value)
      if (value !== rowsPerPage && [2, 25, 50].includes(value)) {
        const existingUrlSearchParams = (`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, ':')}"}`, (key, value) => { return key === "" ? value : decodeURIComponent(value) });
        // console.log(existingUrlSearchParams)
        navigate(ShallowRouting({ ...existingUrlSearchParams, limitRows: value }), { replace: true });
        setRowsPerPage(value);
        setPage(0);
        fetchBranches(null, value, 0)
      }
    };


  const handleSearch = (event) => {
    const value = event.target.value;
    console.log(value);
    setSearchString(value);
    if (value.length >= 3) {
      fetchBranches(value, rowsPerPage, 0)
    }
    if (value.length === 0) {
      fetchBranches(value, rowsPerPage, 0)
    }
  };

  const fetchBranches = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    // setting the loader for it
    setShowLoader(true);
    try {
      // API request for signin
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_branch',
        // company_id: companyId,
        jwt_token: userToken,
        search_string: searchStringForce || searchString,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : (parseInt(pageForce, 10) + 1),
      });
      apiCall(inputData, 'POST', FETCH_BRANCH, 'plain/text')
        .then((response) => {
          console.log(response)
          if (response.data.status === 1) {
            setTotalRows(parseInt(response.data.response.data.total_rows, 10))
            const tempVar = response.data.response.data.branch_info;
            setRowsChange([...tempVar]);
            if (response.data.response.data.branch_info.length < 1) {
              setNoData(true);
            } else {
              setNoData(false)
              setRowsChange(response.data.response.data.branch_info, 'branch_id');
            }
            // (response.data.response.data.branch_info.length < 1) ? setNoData(true) : setNoData(false);
          }
          else {
            console.log(response);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  const handleClick = useHandleClick();
  const handleEdit = (branchId, companyId) => {
    handleClick('/dashboard/addBranch', { branch_id: branchId, company_id: companyId });
  };

  const handleView = (branchId) => {
    handleClick('/dashboard/viewBranch', { branch_id: branchId });
  };
  const isMobileScreen = useMediaQuery('(max-width:450px)');


  const columns = useMemo(() => [
    { id: 'branch_name', name: 'Name', display: true },
    { id: 'branch_city', name: 'City', display: true },
    { id: 'company_name', name: 'Company', display: true },
    { id: 'contact_number', name: 'Contact', display: true },
    { id: 'email', name: 'Email', display: true },
    { id: 'company_id', name: 'Company Id', display: false },
    { id: 'branch_pincode', name: 'Pincode', display: false },
    { id: 'branch_address', name: 'Address', display: false },
    { id: 'branch_gst', name: 'GST NO.', display: false },
    { id: 'action', name: 'Actions', display: true },
    // { id: 'company_id', name: 'CompanyId', display: false },
    { id: 'branch_id', name: 'BranchId', display: false },
  ]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <OutlinedInput
              defaultValue=""
              fullWidth
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              }
              sx={{ maxWidth: 500 }}
              onChange={handleSearch}
            />
          </Card>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          {showLoader ? (
            <CircularProgress margintop={10} size={10} />
          ) : (noData ? <NoData /> : (
            <Container style={{ padding: 0, maxWidth: '1440px' }}>
              {
                rows.length > 0 ? <Paper sx={{ width: '100%' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) =>
                            column.display === true ? <TableCell key={column.id} className={isMobileScreen ? 'sticky-header' : ''}>{column.name}</TableCell> : null
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          rows.map((row, index) => {
                            const branchId = row.branch_id;
                            const companyId = row.company_id
                            return (
                              <TableRow key={index}>
                                {columns &&
                                  columns.map((column, index) => {
                                    const value = row[column.id];
                                    return column.display === true ? (
                                      column.id !== 'action' ? (
                                        <TableCell key={value + column.id} >{value}</TableCell>
                                      ) : (
                                        <TableCell key={branchId} className={isMobileScreen ? 'sticky-column' : ''}>
                                          <IconButton key={'edit'} onClick={() => handleEdit(branchId, companyId)} >
                                            <Edit sx={{ fontSize: 15 }} />
                                          </IconButton>
                                          <IconButton key={'view'} onClick={() => handleView(branchId)} >
                                            <RemoveRedEye sx={{ fontSize: 15 }} />
                                          </IconButton>
                                        </TableCell>
                                      )
                                    ) : null;
                                  })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination

                    rowsPerPageOptions={[2, 25, 50]}
                    page={page}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    onPageChange={(e, value) => handlePageChange(e, value)}
                    onRowsPerPageChange={(e) => { handleRowsPerPageChange(e.target.value) }}
                  />
                </Paper> : <NoData />
              }

            </Container>)
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayBranch;
