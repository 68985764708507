import { useState, useEffect } from 'react'
import { Box, Button, Card, CardActionArea, CardContent, CircularProgress, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import ForgetPass from './ForgetPass'
import ConfirmOtp from './ConfirmOtp'
import ConfirmPassword from './ConfirmPassword'



const ForgotPassword = () => {

    const [activeStep, setActiveStep] = useState(0)
    const [formData, setFormData] = useState({
        username: '',
        otp: '',
        newpassword: '',
        confirmpassword: ''
    })



    // const handleBack = () => {
    //     setActiveStep((prevStep) => prevStep - 1);
    // }
    const handleNext = async (username, usertoken, otp,) => {

        // console.log(`data from child component ${username} ${usertoken}`)


        setFormData({ ...formData, username, usertoken })
        if (otp) {
            setFormData({ ...formData, username, usertoken, otp })

        }
        console.log(formData)
        setActiveStep(activeStep + 1)

    }


    return (
        <>
            <Box>

                <>
                    {activeStep === 0 && (
                        <Stack spacing={3}>
                            <ForgetPass handleNext={handleNext} />
                        </Stack>
                    )}
                    {
                        activeStep === 1 && (
                            <Stack spacing={3}>

                                <ConfirmOtp handleNext={handleNext} userName={formData.username} userToken={formData.usertoken} message={formData.message} />
                            </Stack>
                        )
                    }
                    {
                        activeStep === 2 && (
                            <Stack spacing={3}>

                                <ConfirmPassword userName={formData.username} userToken={formData.usertoken} otp={formData.otp} />
                            </Stack>
                        )
                    }

                </>

            </Box>
        </>
    )
}


export default ForgotPassword