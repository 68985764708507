import { useEffect, useState } from "react";

import { Box, Card, Container, Stack, CardContent, Button, Grid, Typography, Avatar, Divider, Alert, FormControlLabel, Checkbox, CardActions, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { Cancel, FiberManualRecord, } from "@mui/icons-material";
import { Field, useFormik, Formik } from "formik";
import { useSnackbar } from "notistack";
import FormAlerts from "../../../components/form/FormAlerts";
import { SkeletonLoaderSingleForm } from "../../../components/skeleton_loader/skeletonLoader";
import { apiCall } from "../../../components/functions/apiCall";
import TitleDisplay from "../../../components/title_display/TitleDisplay";
import { API_SIGN, FETCH_PRODUCTS_USER_ACESS, GRANT_PRODUCT_ACESS } from "../../../variables/api-variables";
import { decryptToken } from "../../../components/security/securityFunctions";
import palette from "../../../theme/palette";


const ManageUserProductAccess = () => {
    const userToken = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || "{}"
    ).userToken;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const location = useLocation();
    const [userInfo, setUserInfo] = useState('');
    const [productlist, setProductList] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (location.state === null || location.state?.userInfo === null) {
            navigateBack();
        } else {
            setUserInfo(location.state.userInfo);
            fetchProduct();
        }
    }, [])

    const fetchProduct = () => {
        setShowLoader(true);
        try {
            // API request for fetch product
            const inputData = JSON.stringify({
                api_signature: API_SIGN,
                function_type: 'fetch_products_user_access',
                for_user: location.state.userInfo.userid,
                jwt_token: userToken,

            });
            apiCall(inputData, 'POST', FETCH_PRODUCTS_USER_ACESS, 'plain/text')
                .then((response) => {
                    if (response.data.status === 1) {
                        const tempData = response.data.response.product_info;
                        setProductList(tempData)
                        const selectedProductsListTemp = [];
                        tempData.forEach(element => {
                            if (element.in_use === 1) {
                                selectedProductsListTemp.push(element.product_id);
                            }
                        });
                        // setSelectedProducts(selectedProductsListTemp)
                        setSelectedProducts(selectedProductsListTemp)
                        console.log(selectedProducts);
                        console.log(formik.values.product_access_info)
                    } else {
                        console.log(response);
                    }
                    setShowLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };



    const navigateBack = () => {
        if (location.state !== null && location.state?.allowBack !== null && location.state?.allowBack === true) {
            navigateBack()
        } else {
            navigate('/dashboard/users');
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            product_access_info: selectedProducts,
            userid: userInfo?.userid ? userInfo?.userid : '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values, helpers) => {
            setIsLoadingSubmit(true); // set the loading state to true
            try {
                const payload = {
                    ...values,
                    function_type: 'grant_product_access',
                    api_signature: API_SIGN,
                    jwt_token: userToken,
                };
                console.log(payload)
                const inputData = JSON.stringify(payload);
                // Make the API call
                apiCall(
                    inputData,
                    "POST",
                    GRANT_PRODUCT_ACESS,
                    "plain/text"
                ).then((response) => {
                    console.log(response);
                    if (response.data.status === 1) {
                        enqueueSnackbar("User access updated successfully.", { variant: "success" });
                    } else {
                        enqueueSnackbar(response.data.response, { variant: 'error' });
                    }
                    setIsLoadingSubmit(false);
                }).catch((error) => {
                    console.log(error);
                    setErrorMessage(error.message);
                }).finally(() => {
                    setIsLoadingSubmit(false); // set the loading state to false after the API call resolves
                });
            } catch (err) {
                helpers.setStatus({ success: false });
                setErrorMessage(err.message);
                helpers.setSubmitting(false);
                setIsLoadingSubmit(false); // set the loading state to false if an error occurs
            }
        },
    });

    return (
        <>
            <Helmet><title>Manage User Products | Rejoice Solutions</title></Helmet>
            <Container style={{ maxWidth: '1440px' }}>
                <Card variant='"outlined' xs={{ boxShadow: 3, paddingBottom: "20px" }}>
                    <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
                        <Box sx={{ p: 0, margin: '20px 0px 20px 0px' }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <TitleDisplay title="User Product Access" />
                                <Button
                                    color="info"
                                    variant="outlined"
                                    size="medium"
                                    sx={{ marginRight: "10px" }}
                                    onClick={() => {
                                        navigateBack();
                                    }}
                                >
                                    <Cancel />
                                    &nbsp; Cancel
                                </Button>
                            </Stack>
                        </Box>

                        {showLoader ? <SkeletonLoaderSingleForm /> : <>
                            <Card>
                                <CardContent>
                                    <Stack height={'100%'} direction={'row'} justifyContent={'start'} alignItems={'center'} sx={{ margin: '0px 0px 0px 0px' }}>
                                        {userInfo.gender === 'M' ? (
                                            <Avatar
                                                src="/assets/images/avatars/avatar_2.jpg"
                                                variant="circular"
                                                sx={{ height: '50px', width: '50px' }}
                                            />
                                        ) : (
                                            <Avatar
                                                src="/assets/images/avatars/avatar_1.jpg"
                                                variant="circular"
                                                sx={{ height: '50px', width: '50px' }}
                                            />
                                        )}
                                        {/* {console.log(gender)} */}
                                        <Stack direction={'column'} marginLeft={'14px'} padding={'0px'} justifyContent={'center'} alignItems={'start'}>
                                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'500'}
                                                    color={palette.grey[700]}
                                                    align={'left'}
                                                    padding={'0px 0px 0px 0px'}
                                                >
                                                    {userInfo.name}
                                                </Typography>
                                                <FiberManualRecord sx={{ fontSize: 14, marginLeft: '5px' }} color={userInfo.status === "1" ? ("success") : ("error")} />
                                            </Stack>
                                            {userInfo.username !== null ? (
                                                <Typography color={palette.grey[600]} align={'left'}>
                                                    {userInfo.username}
                                                </Typography>
                                            ) : null}
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ margin: '20px 0px' }} />
                                    <form noValidate onSubmit={formik.handleSubmit}>

                                        <Grid container spacing={2} sx={{ padding: '0px' }}>
                                            <Grid item xs={12} sm={12} >
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={'500'}
                                                    color={palette.info.main}
                                                    align={'left'}
                                                    padding={'0px 0px 0px 0px'}
                                                >Products
                                                </Typography>
                                            </Grid>
                                            {productlist.length <= 0 ?
                                                <Alert severity="error">No products found. Kindly try again later.</Alert>
                                                : <>
                                                    {productlist.map((element) => {
                                                        return (

                                                            <Grid item xs={12} sm={6} md={4} key={element.product_id} paddingTop={'12px'}>

                                                                <FormControlLabel
                                                                    key={element.product_id}
                                                                    label={element.name}
                                                                    control={
                                                                        <input type="checkbox" name="product_access_info" style={{ marginRight: '10px' }} value={element.product_id} disabled={isLoadingSubmit} checked={formik.values.product_access_info.includes(element.product_id)} onChange={(e) => {
                                                                            if (!e.target.checked) {
                                                                                // remove this from formik
                                                                                formik.setFieldValue('product_access_info', formik.values.product_access_info.filter(item => item !== e.target.value));
                                                                            } else {
                                                                                formik.setFieldValue('product_access_info', [...formik.values.product_access_info, e.target.value]);
                                                                            }
                                                                        }} />
                                                                    }
                                                                    sx={{ marginLeft: '10px' }}
                                                                />

                                                            </Grid>);
                                                    })
                                                    }
                                                    <FormAlerts errorMessage={errorMessage} />

                                                    <CardActions sx={{ justifyContent: "end", }}>
                                                        <Button
                                                            variant="contained"
                                                            disabled={isLoadingSubmit}
                                                            type="submit"
                                                            sx={{ margin: '20px 0px' }}
                                                        >
                                                            {!isLoadingSubmit ? (
                                                                'Update'
                                                            ) : (
                                                                <CircularProgress color={"info"} size={20} />
                                                            )}
                                                        </Button>
                                                    </CardActions>
                                                </>
                                            }


                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>


                        </>}


                    </CardContent>

                </Card>
                {/* grid closing tag have to be write */}
                {/* search box */}
            </Container>
            {/* grid starting tag have to be wright */}
        </>
    )
}

export default ManageUserProductAccess;