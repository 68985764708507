
import axios from 'axios';
import { API_URL } from '../../variables/api-variables';

export function apiCall(data, method, slug, contentType, responseType = 'json', onUploadProgressCallback = null) {
    const config = {
        'method': method,
        maxBodyLength: Infinity,
        url: API_URL + slug,
        headers: {
            'Content-Type': contentType,
        },
        'responseType': responseType,
        'data': data,
        // onUploadProgress: onUploadProgressCallback
    };
    return new Promise((resolve, reject) => {
        axios.request(config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}