import { useFormik } from 'formik'
import { Alert, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { API_SIGN, FORGET_PASSWORD } from '../../../variables/api-variables'
import { apiCall } from '../../../components/functions/apiCall'

const ConfirmOtp = ({ handleNext, userName, userToken }) => {
    const [message, setMessage] = useState('')
    console.log(message)
    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        onSubmit: async (values, helpers) => {
            try {
                const inputData = JSON.stringify({
                    function_type: "verify_forgot_password",
                    username: userName,
                    otp: values.otp,
                    user_token: userToken,
                    api_signature: API_SIGN
                })
                apiCall(inputData, "POST", FORGET_PASSWORD, 'plain/text')
                    .then((response) => {
                        console.log(response)
                        if (response.data.status === 1) {
                            enqueueSnackbar(response.data.response.success_msg, { variant: "success" })
                            handleNext(response.data.response.username, response.data.response.user_token, values.otp)
                        }
                        else {
                            // enqueueSnackbar("Invalid Otp", { variant: "error" })
                            setMessage(response.data.response)
                        }

                    }).catch((err) => {
                        console.log(err)
                        enqueueSnackbar("Check your Otp again !!", { variant: "error" })
                    });

            } catch (error) {
                console.log(error)
            }
        }
    })
    console.log(formik.values)
    return (

        <>
            <Typography sx={{ mb: 2 }}>
                Enter OTP
            </Typography>

            <form noValidate onSubmit={formik.handleSubmit}>
                <TextField
                    error={!!(formik.touched.otp && formik.errors.otp)}
                    fullWidth
                    color="info"
                    variant="outlined"
                    size="medium"
                    sx={{ marginRight: "10px" }}
                    helperText={formik.touched.otp && formik.errors.otp}
                    label="OTP"
                    name="otp"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.otp}
                />
                {message ? (<Alert sx={{ margin: "20px 0px" }} severity='error'>  {message}</Alert>) : ""}

                <Button fullWidth size="large" sx={{ marginTop: "20px" }} variant='contained' type="submit">Confirm Otp</Button>
            </form>
        </>
    )
}

export default ConfirmOtp