import { Add, ArrowBack, Cancel } from "@mui/icons-material";
import { useState, useEffect } from "react";

import { useFormik } from "formik";

import * as Yup from "yup";
import {
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Select,
    Stack,
    Typography,
    Box,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Skeleton
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useSnackbar } from "notistack";

import { SkeletonLoaderSingleForm } from "../../components/skeleton_loader/skeletonLoader";
import TitleDisplay from "../../components/title_display/TitleDisplay";
import FormAlerts from "../../components/form/FormAlerts";
import { apiCall } from "../../components/functions/apiCall";
import {
    ADD_USER,
    ALTER_USER,
    API_SIGN,
    SPECIFIC_USER

} from "../../variables/api-variables";
import { decryptToken } from "../../components/security/securityFunctions";

export default function CreateUser() {
    const [isCreate, setIsCreate] = useState(true);
    const [formValues, setFormValues] = useState({
        name: '',
        username: '',
        email: '',
        designation: '',
        role: 'other',
        dob: '',
        gender: '',
        mobile: '',
        status: '1'
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const userToken = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || "{}"
    ).userToken;
    const [id, setId] = useState(urlSearchParams.get("user_id") || false);

    const [showLoader, setShowLoader] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const fetchUser = async () => {
        try {
            setShowLoader(true);
            const inputData = JSON.stringify({
                userid: urlSearchParams.get("user_id"),
                function_type: "fetch_specific_user",
                api_signature: API_SIGN,
                jwt_token: userToken,
            });
            apiCall(inputData, "POST", SPECIFIC_USER, "plain/text")
                .then((response) => {
                    if (response.data.status === 1) {
                        console.log(response.data);
                        setFormValues(response.data.response.data);
                        setMobile(response.data.response.data.mobile)
                        setShowLoader(false);
                    } else {
                        setErrorMessage(response.data.response);
                    }
                })
                .catch((error) => {
                    console.log("Error fetching user:", error);
                    setErrorMessage(error.message);
                });
        } catch (err) {
            setShowLoader(false);
            console.log(err);
        }
    };

    useEffect(() => {
        if (urlSearchParams.has("user_id")) {
            setIsCreate(false);
            fetchUser();
        }
    }, []);


    useEffect(() => {
    }, [formValues]);

    const [mobile, setMobile] = useState("");
    const mobileChange = (mobile) => {
        console.log('here');
        setMobile(mobile);
    };



    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues?.name,
            username: formValues?.username,
            email: formValues?.email,
            designation: formValues?.designation != null ? formValues?.designation : '',
            role: formValues?.role === null ? 'other' : formValues?.role,
            dob: formValues?.dob,
            gender: formValues?.gender,
            mobileno: formValues?.mobile,
            status: formValues?.status
        },
        validationSchema: Yup.object({
            name: Yup.string().max(255).required("Name is required"),
            username: Yup.string().max(255).required("Username is required"),
            designation: Yup?.string(),
            role: Yup.string().max(255).required("Role is required"),
            email: Yup.string().max(255).required("Email is required"),
            dob: Yup.string().max(255).required("Date of birth is required"),
            gender: Yup.string().required(),
            status: Yup.string(),
            mobileno: Yup.number()

                .required("Mobile number is required"),
        }),
        onSubmit: async (values, helpers) => {
            setIsLoading(true); // set the loading state to true
            try {
                const payload = {
                    name: values.name,
                    username: values.username,
                    email: values.email,
                    mobile: values.mobileno,
                    dob: values.dob,
                    gender: values.gender,
                    role: values.role,
                    designation: values.designation,
                    status: values.status,
                    function_type: isCreate ? "add_user" : "alter_user",
                    api_signature: API_SIGN,
                    jwt_token: userToken,
                };

                if (!isCreate) {
                    payload.user_id = id;
                }

                console.log(payload)

                const inputData = JSON.stringify(payload);
                // Make the API call
                setIsLoading(true); // set the loading state to true before making the API call
                apiCall(
                    inputData,
                    "POST",
                    isCreate ? ADD_USER : ALTER_USER,
                    "plain/text"
                ).then((response) => {
                    console.log(response);
                    if (response.data.status === 1) {
                        console.log(response);
                        if (isCreate) {
                            enqueueSnackbar("User added successfully.", { variant: "success" });
                            formik.resetForm();
                            setMobile('');
                        } else { enqueueSnackbar("User details updated successfully.", { variant: "success" }) };
                        // setErrorMessage("");
                    } else {
                        enqueueSnackbar(response.data.response, { variant: 'error' });
                    }
                    setIsLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setErrorMessage(error.message);
                }).finally(() => {
                    setIsLoading(false); // set the loading state to false after the API call resolves
                });

            } catch (err) {
                helpers.setStatus({ success: false });
                setErrorMessage(err.message);
                helpers.setSubmitting(false);
                setIsLoading(false); // set the loading state to false if an error occurs
            }
        },
    });

    // optional
    const navigateBack = () => {
        if (
            location.state !== null &&
            location.state.allowBack !== null &&
            location.state.allowBack === true
        ) {
            window.history.back();
        } else {
            Navigate("/dashboard/users");
        }
    };

    const navigate = useNavigate();
    const navigateToAddUser = () => {
        navigate('/dashboard/addUser', { state: { showCancelBtn: true, allowBack: true, componentKey: new Date().getMilliseconds().toString() } }, { replace: true });
        setIsCreate(true);
        setFormValues({
            name: '',
            username: '',
            email: '',
            designation: '',
            role: 'other',
            dob: '',
            gender: '',
            mobile: '',
            status: '1'
        });
        setMobile('');

    };

    return (
        <>
            <Helmet>
                <title>{isCreate ? "Add" : "Update"} User | Rejoice Solutions</title>
            </Helmet>
            <Container style={{ maxWidth: "1440px" }} key={location?.state?.componentKey}>
                <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
                    <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
                        <Box sx={{ p: 0, margin: "10px 0px 10px 0px" }}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <TitleDisplay
                                    title={isCreate ? "Create User" : "Update User"}
                                />
                                <div>
                                {!isCreate ? (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                navigateToAddUser();
                                            }}
                                        >
                                            <Add />
                                            &nbsp; Add
                                        </Button>
                                    ) : null}
                                    {location?.state?.showCancelBtn ? (
                                        <Button
                                            color="info"
                                            variant="text"
                                            size="small"
                                            sx={{ marginRight: "10px" }}
                                            onClick={() => {
                                                navigateBack();
                                            }}
                                        >
                                            <ArrowBack />
                                            &nbsp; Back
                                        </Button>
                                    ) : null}
                                </div>
                            </Stack>

                            {/* starting form here */}
                            {
                                showLoader ? <SkeletonLoaderSingleForm /> : <Box>
                                    <div>
                                        <Card sx={{ p: 2, margin: "15px 0px" }}>
                                            <Grid container>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <form noValidate onSubmit={formik.handleSubmit}>
                                                        <Stack spacing={3}>
                                                            <TextField
                                                                variant="outlined"
                                                                label="Name"
                                                                name="name"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    !!(formik.touched.name && formik.errors.name)
                                                                }
                                                                helperText={
                                                                    formik.touched.name && formik.errors.name
                                                                }
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                fullWidth
                                                                sx={{ marginBottom: "15px" }}
                                                                value={formik.values.name}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                label="Username"
                                                                name="username"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    !!(
                                                                        formik.touched.username &&
                                                                        formik.errors.username
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik.touched.username &&
                                                                    formik.errors.username
                                                                }
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                fullWidth
                                                                sx={{ marginBottom: "15px" }}
                                                                value={formik.values.username}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                label="Email"
                                                                name="email"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    !!(formik.touched.email && formik.errors.email)
                                                                }
                                                                helperText={
                                                                    formik.touched.email && formik.errors.email
                                                                }
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                fullWidth
                                                                sx={{ marginBottom: "15px" }}
                                                                value={formik.values.email}
                                                            />
                                                            <MuiTelInput
                                                                error={!!(formik.touched.mobileno && formik.errors.mobileno)}
                                                                fullWidth
                                                                helperText={formik.touched.mobileno && formik.errors.mobileno}
                                                                label="Mobile"
                                                                name="mobileno"
                                                                onBlur={formik.handleBlur}
                                                                onChange={(e) => {
                                                                    console.log(e);
                                                                    mobileChange(e);
                                                                    formik.setFieldValue('mobileno', e.replace(/\s/g, ""))
                                                                    // formik.handleChange(e);
                                                                }}
                                                                value={mobile}
                                                                type="mobile"
                                                                defaultCountry="IN"
                                                            />

                                                            <TextField
                                                                variant="outlined"
                                                                label="D.O.B"
                                                                type="date"

                                                                name="dob"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    !!(formik.touched.dob && formik.errors.dob)
                                                                }
                                                                helperText={
                                                                    formik.touched.dob && formik.errors.dob
                                                                }
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                fullWidth
                                                                sx={{ marginBottom: "15px" }}
                                                                value={formik.values.dob}
                                                            />

                                                            <FormControl>
                                                                <InputLabel id="gender-select">Gender</InputLabel>
                                                                <Select
                                                                    labelId="gender-select"
                                                                    id="demo-simple-select1"
                                                                    variant="outlined"
                                                                    label="Gender"
                                                                    name="gender"
                                                                    error={
                                                                        !!(
                                                                            formik.touched.gender &&
                                                                            Boolean(formik.errors.gender)
                                                                        )
                                                                    }
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue('gender', e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    fullWidth
                                                                    inputlabelprops={{
                                                                        shrink: true,
                                                                    }}
                                                                    sx={{ marginBottom: '15px' }}
                                                                    value={formik.values.gender || ''}
                                                                >

                                                                    <MenuItem value="M">Male</MenuItem>
                                                                    <MenuItem value="F">Female</MenuItem>
                                                                    <MenuItem value="other">Other</MenuItem>

                                                                </Select>
                                                            </FormControl>


                                                            {formik.values.role !== 'superadmin' ? <FormControl >
                                                                <InputLabel id="role-select">Role</InputLabel>
                                                                <Select
                                                                    labelId="role-select"
                                                                    id="demo-simple-select1"
                                                                    variant="outlined"
                                                                    label="Role"
                                                                    name="role"
                                                                    error={
                                                                        !!(
                                                                            formik.touched.role &&
                                                                            Boolean(formik.errors.role)
                                                                        )
                                                                    }
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue('role', e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    fullWidth
                                                                    inputlabelprops={{
                                                                        shrink: true,
                                                                    }}
                                                                    sx={{ marginBottom: '15px' }}
                                                                    value={formik.values.role || ''}
                                                                >

                                                                    <MenuItem value="other">Other</MenuItem>
                                                                    <MenuItem value="admin">Admin</MenuItem>
                                                                    {/* <MenuItem value="Engineer">Engineer</MenuItem>
                                                                <MenuItem value="Machenic">Mackenik</MenuItem> */}

                                                                </Select>
                                                            </FormControl> : null}


                                                            <TextField
                                                                variant="outlined"
                                                                label="Designation"
                                                                name="designation"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    !!(
                                                                        formik.touched.designation &&
                                                                        formik.errors.designation
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik.touched.designation &&
                                                                    formik.errors.designation
                                                                }
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                fullWidth
                                                                sx={{ marginBottom: "15px" }}
                                                                value={formik.values.designation}
                                                            />
                                                            {formik.values.role !== 'superadmin' ? <FormControl >
                                                                <InputLabel id="role-status">Status</InputLabel>
                                                                <Select
                                                                    labelId="role-status"
                                                                    id="demo-simple-select1"
                                                                    variant="outlined"
                                                                    label="Status"
                                                                    name="status"
                                                                    error={
                                                                        !!(
                                                                            formik.touched.status &&
                                                                            Boolean(formik.errors.status)
                                                                        )
                                                                    }
                                                                    onChange={(e) => {
                                                                        console.log(e)
                                                                        formik.setFieldValue('status', e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    fullWidth
                                                                    inputlabelprops={{
                                                                        shrink: true,
                                                                    }}
                                                                    sx={{ marginBottom: '15px' }}
                                                                    value={formik.values.status || ''}
                                                                >

                                                                    <MenuItem value={'1'}>Active</MenuItem>
                                                                    <MenuItem value={'0'}>Blocked</MenuItem>
                                                                    {/* <MenuItem value="Engineer">Engineer</MenuItem>
                                                                <MenuItem value="Machenic">Mackenik</MenuItem> */}

                                                                </Select>
                                                            </FormControl> : null}
                                                        </Stack>
                                                        {formik.errors.submit && (
                                                            <Typography
                                                                color="error"
                                                                sx={{ mt: 3 }}
                                                                variant="body2"
                                                            >
                                                                {formik.errors.submit}
                                                            </Typography>
                                                        )}
                                                        <FormAlerts errorMessage={errorMessage} />

                                                        <CardActions sx={{ justifyContent: "end", padding: '0px' }}>
                                                            <Button
                                                                variant="contained"
                                                                disabled={isLoading}
                                                                type="submit"
                                                                sx={{ margin: '20px 0px' }}
                                                            >
                                                                {!isLoading ? (
                                                                    isCreate ? (
                                                                        "Add"
                                                                    ) : (
                                                                        "Update"
                                                                    )
                                                                ) : (
                                                                    <CircularProgress color={"info"} size={20} />
                                                                )}
                                                            </Button>
                                                        </CardActions>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </div>
                                </Box>
                            }

                        </Box>
                    </CardContent>
                </Card>
            </Container >
        </>
    );
}
