import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Card, CardContent, CircularProgress, Container, Skeleton, Stack } from "@mui/material";
import Cookies from "js-cookie";
import { API_SIGN, DASHBOARD_DATA } from "../../variables/api-variables";
import { apiCall } from "../../components/functions/apiCall";
import { decryptToken } from "../../components/security/securityFunctions";
import TitleDisplay from "../../components/title_display/TitleDisplay";
import DashboardGrid from "./DashboardGrid";
import DashboardUserChart from "./DashboardUserChart";
import ManageReminder from "../../components/reminder/ManageReminder";
// import { Cookie } from "@mui/icons-material";

const DashboardHome = () => {
    const userToken = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || {}
    ).userToken;

    const role = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || {}
    ).role;

    const [showLoader, setShowLoader] = useState(true);
    const [showDashboardStats, setShowDashboardStats] = useState(false);
    const [datatoShow, setDataToShow] = useState('')

    const fetchDashboardData = async () => {
        setShowLoader(true)
        try {
            const inputData = JSON.stringify({
                api_signature: API_SIGN,
                function_type: 'dashboard',
                jwt_token: userToken,
            })
            apiCall(inputData, "POST", DASHBOARD_DATA, "plain/text").then((response) => {
                if (response.data.status === 1) {
                    setDataToShow(response.data.response)
                } else {
                    console.log(response)
                }
                setShowLoader(false)
            }).catch((err) => {
                console.log(err)
            });



        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (role === 'superadmin' || role === 'admin') {
            fetchDashboardData();
            setShowDashboardStats(true);
        }
        fetchReminders();

    }, [])

    // {/* isLoader :  ? datatoShow !== '' ? */ }

    const fetchReminders = () => {

    }

    return (
        <>
            <Helmet><title>Dashboard | Rejoice Solutions</title></Helmet>

            <Container style={{ maxWidth: "1440px" }} >
                <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
                    <CardContent>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <TitleDisplay title={'Company Dashboard'} />
                        </Stack>
                    </CardContent>
                    {
                        showDashboardStats ?
                            showLoader ? (<CircularProgress margintop={10} size={10} />) : (datatoShow ? (
                                <CardContent>
                                    <DashboardGrid companyCount={datatoShow.total_companies} productsInUse={datatoShow.products_launched_by_superadmin}
                                        totalBranches={datatoShow.total_branches}
                                    />
                                    <DashboardUserChart activeUsers={datatoShow.active_users} blockedUsers={datatoShow.blocked_users} />
                                </CardContent>) : (<Alert severity="error">There is no data to show</Alert>))
                            : null
                    }

                    <CardContent>


                        <ManageReminder />

                    </CardContent>


                </Card>
            </Container>
        </>
    );
}

export default DashboardHome