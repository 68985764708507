import { Box, Button, Card, CardContent, Container, Grid, Skeleton, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import { Navigate, useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import { API_SIGN, SPECIFIC_USER } from '../../variables/api-variables';
import { decryptToken } from '../../components/security/securityFunctions';

import TitleDisplay from '../../components/title_display/TitleDisplay';
import { apiCall } from '../../components/functions/apiCall';
import SpecificUser from './DisplaySpecificUser/SpecificUser';

const UserProfile = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}');
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState(true);
  // const [errorMsg, setErrorMsg] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [userData, setUserData] = useState('');
  const location = useLocation();

  const loadingContent = (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={4} item>
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
        </Grid>{' '}
        <Grid xs={12} sm={8} item>
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
          <Skeleton height={'30px'} />
        </Grid>
      </Grid>
    </Box>
  );

  const fetchSpecificUser = (userId) => {
    closeSnackbar();
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        userid: userId,
        function_type: 'fetch_specific_user',
        api_signature: API_SIGN,
        jwt_token: userToken.userToken,
      });
      apiCall(inputData, 'POST', SPECIFIC_USER, 'plain/text')
        .then((response) => {
          console.log('specific user response=', response);
          if (response.data.status === 1) {
            console.log(response.data);
            setUserData(response.data.response.data);
            setIsLoading(false);
          } else {
            // setIsLoading(false);
            console.log(response);
            enqueueSnackbar(response.data.response, { variant: 'error' });
            setTimeout(() => {
              closeSnackbar();
            }, 5000);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          console.log('Error fetching user:', error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (urlSearchParams.has('user_id')) {
      const userId = urlSearchParams.get('user_id');
      fetchSpecificUser(userId);
    }
  }, []);

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null) {
      window.history.back();
    } else {
      Navigate('/dashboard/users');
    }
  };

  return (
    <>
      <Helmet>
        <title>User Profile | Rejoice Solutions</title>
      </Helmet>

      <Container style={{ maxWidth: '1440px' }}>
        <Card variant='"outlined' xs={{ boxShadow: 3, paddingBottom: '20px' }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '20px 0px 20px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="User Profile" />
                <div>
                  {location?.state ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                </div>
              </Stack>
            </Box>
            {/* <hr /> */}
            {/* user profile image body start */}
            {/* user profile image body  */}
            {isLoading ? loadingContent : <SpecificUser userData={userData} />}
          </CardContent>
        </Card>
        {/* grid closing tag have to be write */}
        {/* search box */}
      </Container>
    </>
  );
};

export default UserProfile;
