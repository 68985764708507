import { Box, Button, Card, CardContent, Container, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import DisplayBranch from './DisplayBranch';
import BreadCrumbs from '../../components/bread-cruumb/BreadCrumbs';

const ManageBranch = () => {
  const navigate = useNavigate();

  const navigateToAddBranch = (showCancel = true) => {
    navigate('/dashboard/addBranch', { state: { showCancelBtn: showCancel, allowBack: true } });
  };

  return (
    <>
      <Helmet>
        {' '}
        <title>Branches | Rejoice Solutions</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="branches" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="Branches" />
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    navigateToAddBranch();
                  }}
                >
                  <AddIcon />
                  &nbsp; Add
                </Button>
              </Stack>
            </Box>
            <Box sx={{ margin: '30px 0px' }}>
              <DisplayBranch />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ManageBranch;
