import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Tab, Box, Tabs, Container, Alert, AlertTitle, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import {Link} from 'react-router-dom'
// components
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import palette from '../../../theme/palette';
import { encryptToken } from '../../../components/security/securityFunctions';
import { apiCall } from '../../../components/functions/apiCall';
// import { API_SIGN, SIGNIN } from 'src/variables/api-variables';
import { API_SIGN, SIGNIN } from '../../../variables/api-variables';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);


  // RJL Login logic
  const [method, setMethod] = useState('email');
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255)
        .required("Username is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // API request for signin
        const inputData = JSON.stringify({
          email: values.email,
          password: values.password,
          api_signature: API_SIGN,
        });
        console.log(inputData);
        apiCall(inputData, 'POST', SIGNIN, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              Cookies.remove('user_data_rejoice_solutions');
              const UserDataRejoiceSolutions = {
                name: response.data.response.name,
                username: response.data.response.username,
                email: response.data.response.email,
                gender: response.data.response.gender,
                userToken: response.data.response.jwt_token,
                role: response.data.response.role
              };
              Cookies.set('user_data_rejoice_solutions', encryptToken(JSON.stringify(UserDataRejoiceSolutions)));
              navigate('/dashboard');
            } else {
              setErrorMessage(response.data.response);
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(error);
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
      }
    },
  });
  const handleMethodChange = useCallback((event, value) => {
    setMethod(value);
  }, []);

  return (
    <>
      <Box>
        <div>
          <Tabs onChange={handleMethodChange} sx={{ mb: 3 }} value={method}>
            <Tab label="Email" value="email" />
          </Tabs>
          {method === 'email' && (
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Username"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email.toLowerCase()}
                />

                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Checkbox name="remember" label="Remember me" checked disabled />Remember me
                </Stack>
                <Link style={{ textDecoration: 'none', color: palette.primary.main, fontSize: '14px' }} to="/forgotpassword">
                  Forgot password?
                </Link>
              </Stack>

              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}

              <LoadingButton fullWidth size="large" type="submit" variant="contained">
                Login
              </LoadingButton>
              {errorMessage ? (
                <Alert severity="error" sx={{ mt: 3 }} variant="standard">
                  <AlertTitle>{errorMessage}</AlertTitle>
                </Alert>
              ) : (
                <Container />
              )}
            </form>
          )}
        </div>
      </Box>
    </>
  );
}


export default LoginForm;