import {
  Button,
  Card,
  Container,
  Grid,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useState } from 'react';

import palette from '../../theme/palette';
import AddReminder from './AddReminder';
import DisplayReminder from './ReminderDisplay/DisplayReminder';

const ManageReminder = () => {

  const [showAddReminder, setShowAddReminder] = useState(false);
  const [reminderComponent, setReminderComponent] = useState(null);
  const [reloadDisplayReminderList, setReloadDisplayReminderList] = useState(0);

  const reloadDisplayList = () => {
    setReloadDisplayReminderList(reloadDisplayList + 1);
  }

  const displayAddReminder = (x = null) => {
    setShowAddReminder(true);
    if (x === null) {
      // create
      setReminderComponent(<AddReminder close={() => {
        setShowAddReminder(false);
      }} displayAddReminder={displayAddReminder} reloadDisplayList={reloadDisplayList} />);
    } else {
      setReminderComponent(<AddReminder reminderId={x} close={() => {
        setShowAddReminder(false);
      }} displayAddReminder={displayAddReminder} reloadDisplayList={reloadDisplayList} />);
    }
  };



  return (
    <>
      {
        // code for add reminder
        showAddReminder ? (
          <Dialog
            open
            sx={{ padding: '0px' }}
            onClose={() => {
              setShowAddReminder(false);
            }}
          >
            {reminderComponent}
          </Dialog >
        ) : null
      }


      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ py: 2 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              padding={'20px 20px 5px 20px'}
              position={'sticky'}
            >
              <Container style={{ padding: '0px' }}>
                <Typography variant="h5" color={palette.info.main}>
                  Reminders
                </Typography>
              </Container>
              <Button variant="text" color="info" size="medium" onClick={() => displayAddReminder()}>
                Add <Add />
              </Button>
            </Stack>


            <DisplayReminder displayAddReminder={displayAddReminder} key={reloadDisplayReminderList} />


          </Card >
        </Grid >
      </Grid >
    </>
  );
};

export default ManageReminder;