import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import NoData from '../../components/no_data/NoData';

import palette from '../../theme/palette';
import { API_SIGN, FETCH_PRODUCT, LAUNCH_PRODUCT } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { decryptToken, encryptRSPass } from '../../components/security/securityFunctions';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import SkeletonLoaderProducts from './SkeletonLoaderProducts';
import BreadCrumbs from '../../components/bread-cruumb/BreadCrumbs';

const LaunchProduct = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
  const [searchString, setSearchString] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [productInLaunch, setProductInLaunch] = useState(null);
  const [data, setData] = useState([]);
  const [role, setRole] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [noData, setNoData] = useState(false);

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      setSearchString(value === '' ? null : value);
    }
    if (value.length === 0) {
      setSearchString(value === '' ? null : value);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [searchString]);

  const fetchProduct = () => {
    setShowLoader(true);
    try {
      // API request for fetch product
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_product',
        jwt_token: userToken,
        search_string: searchString,
      });
      apiCall(inputData, 'POST', FETCH_PRODUCT, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setData(response.data.response.product_info);
            setRole(response.data.response.role);
          } else {
            setNoData(true);
            console.log(response);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const launchProduct = (productId) => {
    // setShowLoader(true);
    try {
      // API request for fetch product
      const inputData = JSON.stringify({
        function_type: 'create_user_products',
        product_id: productId,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', LAUNCH_PRODUCT, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            // const storedData = response.data.response;
            // console.log(storedData)
            // console.log(encryptRSPass(storedData))
            // // const redirectUrl = `http://localhost:3000/auth/authPass?rspass=${encodeURIComponent(encryptRSPass(storedData))}`;
            // const redirectUrl = `https://rejoicefinware.in/auth/authPass?rspass=${encodeURIComponent(encryptRSPass(storedData))}`;
            const storedData = response;
            const redirectUrl = `${response.data.response.url}?rspass=${encryptRSPass(JSON.stringify(storedData))}`;
            window.open(redirectUrl, '_blank');
            setShowLoader(false);
            setProductInLaunch(null);
          } else {
            console.log(response);
            enqueueSnackbar(response.data.response, { variant: 'error' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        {' '}
        <title>Products | Rejoice Solutions</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="products" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="Products" />
              </Stack>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Card sx={{ p: 2 }}>
                    <OutlinedInput
                      defaultValue=""
                      fullWidth
                      placeholder="Search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SvgIcon color="action" fontSize="small">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      }
                      sx={{ maxWidth: 500 }}
                      onChange={handleSearch}
                    />
                  </Card>
                </Grid>
              </Grid>
              {showLoader ? (
                <SkeletonLoaderProducts />
              ) : noData ? (
                <NoData />
              ) : (
                <Container style={{ padding: '30px 0px', maxWidth: '1440px' }}>
                  <Grid container spacing={2}>
                    {data?.map((element) => {
                      const canLaunch = element.in_use || role === 'superadmin';
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={element.product_id}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          {canLaunch ? (
                            <Button
                              disabled={productInLaunch !== null}
                              variant="outlined"
                              sx={{
                                opacity: productInLaunch && productInLaunch !== element.product_id ? '0.5' : '1',
                                padding: '10px 0px',
                                width: '100%',
                                maxWidth: '250px',
                                height: '100%',
                                maxHeight: '250px',
                                borderColor: palette.grey[200],
                              }}
                              onClick={() => {
                                if (element.in_use) {
                                  setProductInLaunch(element.product_id);
                                  launchProduct(element.product_id);
                                  // hit api
                                }
                              }}
                              onMouseOver={() => setIsHovered(element.product_id)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <Box align="center" sx={{ width: '100%', height: '100%', maxHeight: '250px' }}>
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                  <img alt={`Rejoice ${element.name}`} src={element.logo} width={'150px'} />
                                  {canLaunch && element.in_use === 0 && element.product_id === isHovered ? (
                                    <Button
                                      sx={{ position: 'absolute', color: 'white' }}
                                      variant={'contained'}
                                      color={'primary'}
                                      onClick={() => launchProduct(element.product_id)}
                                    >
                                      Launch
                                    </Button>
                                  ) : null}
                                  {productInLaunch && productInLaunch === element.product_id ? (
                                    <CircularProgress sx={{ position: 'absolute' }} size={30} color="warning" />
                                  ) : null}
                                </Stack>
                              </Box>
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              sx={{
                                padding: '10px 0px',
                                width: '100%',
                                maxWidth: '250px',
                                height: '100%',
                                maxHeight: '250px',
                                borderColor: palette.grey[200],
                                background: palette.grey[100],
                                opacity: 0.5,
                              }}
                              disabled
                            >
                              <Box align="center" sx={{ width: '100%', height: '100%', maxHeight: '250px' }}>
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                  <img alt={`Rejoice ${element.name}`} src={element.logo} width={'150px'} />
                                </Stack>
                              </Box>
                            </Button>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Container>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default LaunchProduct;
