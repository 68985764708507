import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import Logo from '../../components/logo';
import LoginPage from "../login/signin/LoginPage";
import Router from "../../routes";
import Register from "../login/register/Register";


const InitialLoading = () => {

    // checking if it is logged in or not


    return (

        <Container style={{ height: '100vh', width: '100%' }} >
            <Stack justifyContent={'center'} alignItems={'center'} direction={'column'} height={'100%'}>
                <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                    <Logo sx={{ width: 100, height: 100 }} />
                </Box>
                <Stack direction={'row'} sx={{ padding: '30px 0px', width: '100%' }} justifyContent={'center'}><CircularProgress size={20} /></Stack>
            </Stack>
        </Container>

    )

}
export default InitialLoading;