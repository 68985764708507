import { Avatar, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import Chart from 'react-google-charts'

import React from 'react'
import palette from '../../theme/palette'

const DashboardUserChart = ({ activeUsers, blockedUsers }) => {
    const data = [
        ["User", "Total users"],
        ["Active Users", Number(activeUsers)],
        ["Blocked Users", Number(blockedUsers)]
    ]
    const options = {
        colors: ["green", "red"],
        title: "Total Users ",
        pieHole: .5,
        is3D: true
    }
    return (
        <><Grid container spacing={1} justifyContent={'space-between'} alignItems={''} sx={{ margin: "20px 0px" }}>
            <Grid xs={12} sm={6} item style={{ padding: '0px' }}>


                <CardContent sx={{ width: '100%', height: '100%', backgroundColor: "#9adc5fd1", margin: '0px' }}>
                    <Stack height={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Avatar
                            src="/assets/images/avatars/avatar_15.jpg"
                            variant="large"
                            sx={{ height: '75px', width: '75px', }}
                        />
                        <Typography
                            variant="h3"
                            fontWeight={'500'}
                            color={"white"}
                            align={'center'}
                            padding={'20px 0px 0px 0px'}
                        >
                            {Number(activeUsers) + Number(blockedUsers)}
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight={'500'}
                            color={"white"}
                            align={'center'}
                            padding={'0px 0px 0px 0px'}
                        >
                            Total Users
                        </Typography>
                    </Stack>
                </CardContent>
            </Grid>
            <Grid xs={12} sm={6} item>


                <CardContent sx={{ padding: '30px 10px', width: '100%', height: '100%', margin: "5px" }}>
                    <Stack height={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} >

                        <Chart
                            chartType='PieChart'
                            width="100%"
                            height="100%"
                            data={data}
                            options={options}
                            variant="h3"
                            fontWeight={'500'}
                            color={palette.grey[700]}
                            align={'center'}
                            padding={'20px 0px 0px 0px'}
                            backgroundColor={"#orange"}
                        />


                    </Stack>
                </CardContent>
            </Grid>

        </Grid ></>
    )
}

export default DashboardUserChart