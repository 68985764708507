import { Box, Container, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../components/logo';

const RegistrationSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Registration Successful | Rejoice Solutions</title>
      </Helmet>
      <Logo
        sx={{
          // position: 'fixed',
          // top: { xs: 16, sm: 24, md: 0 },
          // left: { xs: 16, sm: 24, md: 40 },
          // marginBottom: '20px'
        }}
      />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              paddingBottom: 5,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img
                alt="Registration Successful"
                src="/assets/graphics/registration_successful.png"
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 400,
                }}
              />
            </Box>
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              Registration Successful.
            </Typography>
            <Typography
              align="center"
              color={'GrayText'}
              variant="body1"
            >
              Workspace created successfully. Kindly check your email for
              password.
            </Typography>
            <Button
              onClick={() => {
                navigate('/login');
              }}
              sx={{ mt: 3, textTransform: "capitalize !important", fontFamily: "Montserrat", fontSize: "1.2rem" }}
              variant="contained"
            >
              Login Now
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default RegistrationSuccess