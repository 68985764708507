import { Box, Container, FormControl, FormControlLabel, OutlinedInput, Button, Paper, Select, Stack, TextField, InputLabel, MenuItem, Typography, Grid } from "@mui/material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { MoreHoriz, ExpandLess } from "@mui/icons-material";
import palette from "../../../theme/palette";
import { getDateYmd } from "../../functions/formattingFunctions";
import { API_SIGN, FETCH_REMINDER } from "../../../variables/api-variables";
import { decryptToken } from "../../security/securityFunctions";
import { apiCall } from "../../functions/apiCall";
import { SkeletonLoaderSingleForm } from "../../skeleton_loader/skeletonLoader";
import DisplayReminderRows from "./DisplayReminderRows";

const DisplayReminder = (props) => {

    const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
    const [showLoader, setShowLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [reminderRowsKey, setReminderRowsKey] = useState(0);

    const handlePageChange = (value) => {
        setPage(value);
    };

    const rowsPerPage = 3;
    const [rows, setRowsChange] = useState([]);

    const fetchReminders = (searchString, markStatus, mindate, maxdate) => {
        setShowLoader(true);
        try {
            // API request for fetch reminders
            const inputData = JSON.stringify({
                function_type: 'fetch_reminders',
                api_signature: API_SIGN,
                jwt_token: userToken,
                search_string: searchString || formik.values.searchString,
                mark_status: markStatus || formik.values.status,
                mindate: mindate || formik.values.mindate,
                maxdate: maxdate || formik.values.maxdate,
                limit: rowsPerPage,
                page: page + 1,
            });
            apiCall(inputData, 'POST', FETCH_REMINDER, 'plain/text')
                .then((response) => {
                    if (response.data.status === 1) {
                        setShowLoader(false);
                        const reminderData = response.data.response.data.reminder_data;
                        if (reminderData && reminderData.length > 0) {
                            setRowsChange([...reminderData]);
                            setReminderRowsKey(reminderRowsKey + 1);
                            setTotalRows(response.data.response.data.total_rows);
                        } else {
                            setRowsChange([]);
                        }
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchReminders();
    }, [page]);


    // for formik initial values

    const [filterValue, setFitlerValue] = useState({
        status: '0',
        searchString: '',
        mindate: getDateYmd(new Date()),
        maxdate: getDateYmd(new Date()),
    });

    // filter end
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: filterValue.status,
            searchString: filterValue.searchString,
            mindate: filterValue.mindate,
            maxdate: filterValue.maxdate
        },
    });

    const [displayFilters, setDisplayFilters] = useState(false);

    return (
        <>

            <Container style={{ margin: '0px 0px 10px 0px', padding: '0px 0px', maxHeight: '60vh', overflowY: 'auto ', }} maxWidth={false}>
                <Container style={{}} maxWidth={false}>
                    <Grid container sx={{ padding: '0px' }} spacing={2}>
                        <Grid item xs={12} style={{ justifyContent: 'end', display: 'flex' }}>

                            <Button variant="text" size="small" sx={{ color: palette.grey[600] }} onClick={() => { setDisplayFilters(!displayFilters) }}>{displayFilters ? <ExpandLess /> : <MoreHoriz />}</Button>
                        </Grid>
                        {displayFilters
                            ?
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <OutlinedInput
                                        fullWidth
                                        placeholder="Search"
                                        name="searchString"
                                        size='small'
                                        sx={{ marginRight: '10px', maxWidth: 500 }}
                                        onChange={(e) => {
                                            formik.setFieldValue('searchString', e.target.value);
                                            // if (e.target.value.length > 2) {
                                            setPage(0);
                                            fetchReminders(e.target.value, null, null, null);
                                            // }
                                        }}
                                        value={formik.values.searchString}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-select">Status</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            labelId="status-select"
                                            id="demo-simple-select1"
                                            variant="outlined"
                                            label="Status"
                                            name="status"
                                            error={!!(formik.touched.status && Boolean(formik.errors.status))}
                                            onChange={(e) => {
                                                formik.setFieldValue('status', e.target.value);
                                                setPage(0);
                                                fetchReminders(null, e.target.value, null, null);
                                            }}
                                            onBlur={formik.handleBlur}
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            sx={{ marginRight: '10px' }}
                                            value={formik.values.status}
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="1">Completed</MenuItem>
                                            <MenuItem value="0">Pending</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        variant="outlined"
                                        label="Start Date"
                                        type="date"
                                        name="mindate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={
                                            !!(formik.touched.mindate && formik.errors.mindate)
                                        }
                                        helperText={
                                            formik.touched.mindate && formik.errors.mindate
                                        }
                                        onChange={(e) => {
                                            formik.setFieldValue('mindate', e.target.value);
                                            setPage(0);
                                            fetchReminders(null, null, e.target.value, null);
                                        }}
                                        onBlur={formik.handleBlur}
                                        sx={{ marginRight: '10px' }}
                                        value={formik.values.mindate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        variant="outlined"
                                        label="End Date"
                                        type="date"
                                        name="maxdate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={
                                            !!(formik.touched.maxdate && formik.errors.maxdate)
                                        }
                                        helperText={
                                            formik.touched.maxdate && formik.errors.maxdate
                                        }
                                        onChange={(e) => {
                                            formik.setFieldValue('maxdate', e.target.value);
                                            setPage(0);
                                            fetchReminders(null, null, null, e.target.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        sx={{ marginRight: '10px' }}
                                        value={formik.values.maxdate}
                                    />
                                </Grid>
                            </>
                            : null}
                    </Grid>

                </Container>
            </Container>
            {
                showLoader ?
                    <Container style={{ margin: '0px 0px', padding: '10px 10px' }}>
                        <SkeletonLoaderSingleForm />
                    </Container> : <DisplayReminderRows userToken={userToken} keyId={reminderRowsKey} reminderData={rows} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePageChange={handlePageChange} displayAddReminder={props.displayAddReminder} />
            }
        </>
    );
}

export default DisplayReminder;