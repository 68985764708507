import { Add, ArrowBack } from '@mui/icons-material';
import {
  Container,
  Card,
  Box,
  Stack,
  Button,
  CardContent,
  TextField,
  Grid,
  CircularProgress,
  CardActions,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import { apiCall } from '../../components/functions/apiCall';
import { ADD_COMPANY, API_SIGN, SPECIFIC_COMPANY } from '../../variables/api-variables';
import FormAlerts from '../../components/form/FormAlerts';
import { decryptToken } from '../../components/security/securityFunctions';

const AddCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
  // const { handleClick } = props;
  const [errorMessage, setErrorMessage] = useState('');
  // const [title, setTitle] = useState('');
  const [formValues, setFormValues] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [id, setId] = useState(urlSearchParams.get('company_id') || false);
  const [isCreate, setIsCreate] = useState(true);

  const resetForm = () => {
    setFormValues('');
  }

  const navigateToAddCompany = () => {
    // navigate('/addCompany');
    navigate('/dashboard/addCompany', { state: { showCancelBtn: true } }, { replace: true });
    setFormValues({
      name: '',
      address: '',
      pan: '',

    });
    setIsCreate(true);
  };

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null && location.state.allowBack === true) {
      window.history.back();
    } else {
      navigate('/dashboard/companies');
    }
  };

  const fetchCompany = async () => {
    try {
      setIsLoading(true);
      const inputData = JSON.stringify({
        company_id: urlSearchParams.get('company_id'),
        function_type: 'fetch_specific_company',
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      apiCall(inputData, 'POST', SPECIFIC_COMPANY, 'plain/text')
        .then((response) => {
          if (response.data.status === 1) {
            setFormValues(response.data.response.data);
            setIsLoading(false);
          } else {
            // setIsLoading(false);
            setErrorMessage(response.data.response);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          console.log('Error fetching company:', error);
          setErrorMessage(error.message);
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (urlSearchParams.has('company_id')) {
      setIsCreate(false);
      fetchCompany();
    }
  }, []);

  const buttonTitle = isCreate ? 'Add' : 'Update';

  // useEffect(() => {
  // }, [formValues]);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: formValues?.company_name ? formValues?.company_name : '',
      address: formValues?.company_address ? formValues?.company_address : '',
      pan: formValues?.company_pan ? formValues?.company_pan : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required'),
      address: Yup.string().max(255).required('Address is required'),
      pan: Yup.string()
        .max(255)
        .required('PAN is required')
        .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Is not in correct format'),
    }),
    onSubmit: async (values, helpers) => {
      setIsLoading(false);
      setErrorMessage('')
      try {
        // API request for add company
        const payload = {
          company_name: values.name,
          company_address: values.address,
          company_pan: values.pan,
          // product_id: "1",
          function_type: isCreate ? 'add_company' : 'alter_company',
          api_signature: API_SIGN,
          jwt_token: userToken,
        };

        if (!isCreate) {
          payload.company_id = id;
        }

        const inputData = JSON.stringify(payload);
        // Make the API call
        setIsLoading(true); // set the loading state to true before making the API call
        apiCall(inputData, 'POST', ADD_COMPANY, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar(isCreate ? 'Company added successfully. ' : 'Company details updated successfully', {
                variant: 'success',
              });
              if (isCreate) {
                formik.resetForm();
                resetForm();
              }
              navigate('/dashboard/companies')
            } else {
              setErrorMessage(response.data.response);
            }
          })
          .catch((error) => {
            console.log('Error adding company:', error.message);
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false); // set the loading state to false after the API call resolves
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false); // set the loading state to false if an error occurs
      }
    },
  });

  // if we will receive props id that means it is now in update phase

  return (
    <>
      <Helmet>
        <title>{isCreate ? 'Add' : 'Update'} Company | Rejoice Solutions</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title={isCreate ? 'Create Company' : 'Update Company'} />
                <div>
                  {location.state && location.state.showCancelBtn ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                  {!isCreate ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          navigateToAddCompany();
                        }}
                      >
                        <Add />
                        &nbsp; Add
                      </Button>
                      <Button
                        color="info"
                        variant="text"
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          navigateBack();
                        }}
                      >
                        <ArrowBack />
                        &nbsp; Back
                      </Button>
                    </>
                  ) : null}
                </div>
              </Stack>

              {/* starting form here */}
              <Box>
                <div>
                  <Card sx={{ p: 2, margin: '15px 0px' }}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                          <Stack spacing={3}>
                            <TextField
                              variant="outlined"
                              label="Name"
                              name="name"
                              error={!!(formik.touched.name && formik.errors.name)}
                              helperText={formik.touched.name && formik.errors.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '15px' }}
                              value={formik.values.name}
                            />
                            <TextField
                              variant="outlined"
                              label="Address"
                              name="address"
                              error={!!(formik.touched.address && formik.errors.address)}
                              helperText={formik.touched.address && formik.errors.address}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '15px' }}
                              value={formik.values.address}
                            />
                            <TextField
                              variant="outlined"
                              label="PAN"
                              name="pan"
                              error={!!(formik.touched.pan && formik.errors.pan)}
                              helperText={formik.touched.pan && formik.errors.pan}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '15px' }}
                              value={formik.values.pan}
                            />
                          </Stack>
                          {formik.errors.submit && (
                            <Typography color="error" sx={{ mt: 3 }} variant="body2">
                              {formik.errors.submit}
                            </Typography>
                          )}
                          <FormAlerts errorMessage={errorMessage} />

                          <CardActions sx={{ justifyContent: 'end', padding: '0px' }}>
                            <Button variant="contained" disabled={isLoading} type="submit" sx={{ margin: '20px 0px' }}>
                              {!isLoading ? buttonTitle : <CircularProgress color={'info'} size={20} />}
                            </Button>
                          </CardActions>
                        </form>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddCompany;
