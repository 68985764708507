import { Alert, Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { apiCall } from '../../../components/functions/apiCall'
import { API_SIGN, FORGET_PASSWORD } from '../../../variables/api-variables'

const ConfirmPassword = ({ userName, userToken, otp }) => {
    const [message, setMessage] = useState('')

    const [gotoLoginPage, setGotLoginPage] = useState(false)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            newpassword: '',
            confirmpassword: ''
        },
        onSubmit: async (values, helper) => {
            try {
                const inputData = JSON.stringify({
                    function_type: "reset_password",
                    username: userName,
                    otp,
                    user_token: userToken,
                    new_password: values.newpassword,
                    confirm_password: values.confirmpassword,
                    api_signature: API_SIGN
                })
                console.log(inputData)
                apiCall(inputData, 'POST', FORGET_PASSWORD, 'plain/text')
                    .then((response) => {
                        console.log(response)
                        if (response.data.status === 1) {
                            enqueueSnackbar('Password has been successfully reset.', { variant: "success" })
                            setGotLoginPage(true)
                            setTimeout(() => {
                                navigate("/login", { replace: true })
                            }, 0)
                        }
                        else {
                            setMessage(response.data.response)
                            // enqueueSnackbar("New password and confirm password not matched", { variant: "error" })
                        }
                    }).
                    catch((err) => {
                        console.log(err)
                    })

            } catch (error) {
                console.log(error)
            }
        }
    })
    return (
        <>
            <Typography sx={{ mb: 3 }}>
                Enter new password
            </Typography>

            <form noValidate onSubmit={formik.handleSubmit} >



                <TextField
                    error={!!(formik.touched.newpassword && formik.errors.newpassword)}
                    fullWidth
                    helperText={formik.touched.newpassword && formik.errors.newpassword}
                    label="New Password"
                    name="newpassword"
                    type='password'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}

                    value={formik.values.newpassword}
                />
                <TextField
                    error={!!(formik.touched.confirmpassword && formik.errors.confirmpassword)}
                    fullWidth
                    helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                    label="Confirm Password"
                    name="confirmpassword"
                    type='password'
                    sx={{ marginTop: "15px" }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}

                    value={formik.values.confirmpassword}
                />
                {message ? <Alert sx={{ margin: "20px 0px" }} severity='error'>{message}</Alert> : ""}

                <Button fullWidth size="large" sx={{ marginTop: "20px" }} variant='contained' type="submit">Confirm Password</Button>

            </form>
            {gotoLoginPage && (
                <Link to="/login" variant="subtitle2" underline="hover">Login Page</Link>
            )}

        </>
    )
}

export default ConfirmPassword