import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardContent, Container, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import DisplayUser from './DisplayUser';
import BreadCrumbs from '../../components/bread-cruumb/BreadCrumbs';
import BackButon from '../../components/backButton/BackButon';
// import DisplayCompany from './DisplayCompanies';

const ManageUser = () => {
    // this file is the main file for managing companies

    const navigate = useNavigate();

    const navigateToAddUser = (showCancel = true) => {
        navigate('/dashboard/addUser', { state: { showCancelBtn: showCancel, allowBack: true } });
        // navigate('/dashboard/addCompany', { state: { showCancelBtn: showCancel, allowBack: true } });
    }

    return (
        <>
            <Helmet> <title>Users | Rejoice Solutions</title></Helmet>
            <Container style={{ maxWidth: '1440px' }}>
                <BreadCrumbs path={"users"} />
                <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
                    <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
                        <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <TitleDisplay title="Users" />
                                <Button
                                    variant="contained"
                                    size='medium'
                                    onClick={() => {
                                        navigateToAddUser();
                                    }}
                                >
                                    <AddIcon />
                                    &nbsp; Add
                                </Button>
                            </Stack>
                        </Box>
                        <Box sx={{ margin: '30px 0px' }}>
                            <DisplayUser />
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default ManageUser;


