import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useHandleClick = () => {
  const navigate = useNavigate();

  const handleClick = useCallback((link, query, stateParams, options) => {
    let newUrl = '';
    if (typeof link === 'string') {
      newUrl = link;
    } else {
      newUrl = window.location.pathname;
    }

    if (typeof query === 'object') {
      const params = Object.entries(query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      newUrl = `${newUrl}?${params}`;
    }

    navigate(newUrl, { state: { shallow: true, ...stateParams }, ...options });
  }, [navigate]);

  return handleClick;
};