import {

    Card,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Paper,
    Stack,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    MenuItem,
    MenuList,
    Chip,
    useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, unstable_HistoryRouter as useHistory } from "react-router-dom";
import {

    Edit,
    FiberManualRecord,
    MoreHoriz,
    RemoveRedEye,
    StarsRounded,
    VpnKey,
} from "@mui/icons-material";

import './displayUser.css'

import {
    ALTER_USER,
    API_SIGN,
    FETCH_USER,
} from "../../variables/api-variables";
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { decryptToken } from "../../components/security/securityFunctions";
import { apiCall } from "../../components/functions/apiCall";

import { useHandleClick } from "../../components/list/use-handle-click";
import { displayMobileNumber } from "../../components/functions/formattingFunctions";
import NoData from "../../components/no_data/NoData";
import { ShallowRouting } from "../../components/routing/ShallowRouting";

const DisplayUser = () => {
    // pagination in url recieved?
    // search
    const navigate = useNavigate();
    const userToken = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || "{}"
    ).userToken;
    const [searchString, setSearchString] = useState("");
    // const [data, setData] = useState();
    // const [length, setLength] = useState(0);
    // const [ids, setIds] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [rows, setRowsChange] = useState([]);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0); // defaults
    const [rowsPerPage, setRowsPerPage] = useState(15); // default
    const [userId, setUserId] = useState(urlSearchParams.get("user_id") || null);
    const [noData, setNoData] = useState(false)
    const [totalRows, setTotalRows] = useState(0);

    // const [isCreate, setIsCreate] = useState(true);
    // console.log(userId);
    // const location = useLocation();
    console.log('')
    const isMobileScreen = useMediaQuery('(max-width:450px)');
    console.log(isMobileScreen)
    // console.log(isMobileScreen ? "mobile device" : 'pc device   ')
    // const navigate = useNavigate();
    const handlePageChange = (e, value) => {
        console.log(value)
        const existingUrlSearchParams = urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => { return key === "" ? value : decodeURIComponent(value) }) : null;
        setPage(value);
        navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
        fetchUsers(null, null, value.toString());
        // setPage(value);
    };

    const handleRowsPerPageChange = (value) => {

        if (value !== rowsPerPage && [15, 25, 50].includes(value)) {
            const existingUrlSearchParams = (`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => { return key === "" ? value : decodeURIComponent(value) });
            navigate(ShallowRouting({ ...existingUrlSearchParams, limitRows: value }), { replace: true });
            setRowsPerPage(value);
            setPage(0);
            fetchUsers(null, value, 0)
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        if (value.length >= 3) {
            fetchUsers(value, rowsPerPage, 0)
            // setSearchString(value === "" ? null : value);
        }
        if (value.length === 0) {
            fetchUsers(value, rowsPerPage, 0)
            // setSearchString(value === "" ? null : value);
        }
    };

    const fetchUsers = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
        // setting the loader for it
        setShowLoader(true);
        try {
            // API request for users details
            const inputData = JSON.stringify({
                api_signature: API_SIGN,
                function_type: "fetch_users",
                jwt_token: userToken,
                search_string: searchStringForce || searchString,
                limit: rowsPerPageForce || rowsPerPage,
                page: !pageForce ? page + 1 : (parseInt(pageForce, 10) + 1)
            });
            // console.log(inputData);
            apiCall(inputData, "POST", userId ? ALTER_USER : FETCH_USER, "plain/text")
                .then((response) => {
                    setShowLoader(false)
                    console.log(response)
                    if (response.data.status === 1) {
                        setNoData(false)
                        setTotalRows(parseInt(response.data.response.data.total_rows, 10))
                        const tempVar = response.data.response.data.users_info;
                        setRowsChange([...tempVar]);
                    } else {
                        setNoData(true)
                        console.log(response);
                    }
                    setShowLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const columns = useMemo(() => [
        { id: "user_id", name: "UserId", display: false },
        { id: "name", name: "Name", display: true },
        { id: "email", name: "Email", display: true },
        { id: "mobile", name: "Mobile Number", display: true },
        { id: "role", name: "Role", display: false },
        { id: "designation", name: "Designation", display: false },
        { id: "status", name: "status", display: false },
        { id: "action", name: "Action", display: true },

    ]);

    const handleClick = useHandleClick();

    const handleEdit = (userId) => {
        handleClick("/dashboard/addUser", { user_id: userId }, { showCancelBtn: true, allowBack: true, componentKey: new Date().getMilliseconds().toString() });
    };

    // have to fetch all the information about user
    const handleProfile = (userId) => {
        // console.log(`userData===`, userId);
        console.log(`handle profile button `, userId)
        // have to send user all details
        handleClick("/dashboard/viewUser", { user_id: userId });
    };

    const handleUserPrivileges = (userInfo) => {
        console.log("user info =", userInfo)
        handleClick("/dashboard/manageUserProducts", {}, { userInfo });
        console.log(userInfo)
    }

    const [moreOptionsVisibleKey, SetMoreOptionsVisibleKey] = useState(null);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Card sx={{ p: 2 }}>
                        <OutlinedInput
                            defaultValue=""
                            fullWidth
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SvgIcon color="action" fontSize="small">
                                        <SearchIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            }
                            sx={{ maxWidth: 500 }}
                            onChange={handleSearch}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    {showLoader ? (
                        <CircularProgress margintop={10} size={10} />
                    ) : (noData ? <NoData /> : (
                        <Container style={{ padding: 0, maxWidth: "1440px" }}>
                            {rows.length > 0 ? <Paper sx={{ width: "100%" }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) =>
                                                    column.display === true ? (
                                                        column.id === 'action' ? (<TableCell key={column.id} className={isMobileScreen ? 'sticky-header' : ''}>
                                                            {column.name}
                                                        </TableCell>) : (<TableCell key={column.id}>
                                                            {column.name}
                                                        </TableCell>)
                                                    ) : null
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows &&
                                                rows

                                                    .map((row, index) => {
                                                        const userId = row.userid;
                                                        return (
                                                            <TableRow key={index}>
                                                                {columns &&
                                                                    columns.map((column, index) => {
                                                                        const value = row[column.id];
                                                                        return column.display === true ? (
                                                                            column.id !== "action" ? (
                                                                                <TableCell key={Math.random()}  >
                                                                                    <Stack alignItems='center' direction={"row"} p={0}>
                                                                                        {column.id === 'mobile' ? displayMobileNumber(value) : value}
                                                                                        {/* {console.log(`status=`, row)} */}
                                                                                        {/* {column.id === 'status' && row.status === "1" ? "Active" : "Inactive"} */}
                                                                                        {
                                                                                            column.id === "name" && row.role === "superadmin" ? (
                                                                                                <>
                                                                                                    &nbsp;<StarsRounded sx={{ color: "#ff8346", fontSize: '18px' }} />

                                                                                                </>
                                                                                            ) : (null
                                                                                            )
                                                                                        }
                                                                                        {column.id === "name" && row.status !== null ? (< FiberManualRecord sx={{ fontSize: 18, marginLeft: '5px' }} color={row.status === "1" ? ("success") : ("error")} />) : ""}
                                                                                    </Stack>


                                                                                    {column.id === "name" && row.designation !== null ? <Chip label={row.designation} variant="filled" size="small" /> : ''}



                                                                                </TableCell>
                                                                            ) : (
                                                                                <TableCell key={userId} className={isMobileScreen ? 'sticky-column' : ''}>

                                                                                    {/* have to write user privlage or key button */}
                                                                                    <IconButton
                                                                                        key="eye"
                                                                                        onClick={() =>
                                                                                            handleProfile(userId)
                                                                                        }
                                                                                        itemproperty={row}
                                                                                    >
                                                                                        {" "}
                                                                                        {
                                                                                            <>
                                                                                                <RemoveRedEye sx={{ fontSize: 15 }} />
                                                                                            </>
                                                                                        }
                                                                                    </IconButton>
                                                                                    {row.role !== "superadmin" ?
                                                                                        <IconButton
                                                                                            key="profile"
                                                                                            onClick={() => {
                                                                                                if (moreOptionsVisibleKey === userId) {
                                                                                                    SetMoreOptionsVisibleKey('');
                                                                                                } else SetMoreOptionsVisibleKey(userId);
                                                                                            }}
                                                                                        >

                                                                                            <MoreHoriz sx={{ fontSize: 15 }} />

                                                                                        </IconButton> : null}
                                                                                    {
                                                                                        moreOptionsVisibleKey === userId ?
                                                                                            <MenuList sx={{ position: 'absolute', zIndex: 3, background: '#fff', border: 'solid #dedede thin', borderRadius: '5px', padding: '0px' }}>
                                                                                                <MenuItem onClick={() => handleEdit(userId)}  >Edit &nbsp; <Edit sx={{ fontSize: 15 }} />
                                                                                                </MenuItem>
                                                                                                <MenuItem key={userId} onClick={() => { console.log(row); handleUserPrivileges(row) }}>Privileges &nbsp;
                                                                                                    <VpnKey sx={{ fontSize: 15 }} />
                                                                                                </MenuItem>
                                                                                            </MenuList>
                                                                                            : null
                                                                                    }


                                                                                </TableCell>
                                                                            )
                                                                        ) : null;
                                                                    })}
                                                            </TableRow>
                                                        );
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[15, 25, 50]}
                                    page={page}
                                    count={totalRows}
                                    rowsPerPage={rowsPerPage}
                                    component="div"
                                    onPageChange={(e, value) => handlePageChange(e, value)}
                                    onRowsPerPageChange={(e) => { handleRowsPerPageChange(e.target.value) }}
                                />
                            </Paper> : <NoData />}

                        </Container>)
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default DisplayUser;
