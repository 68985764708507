export const API_URL = 'https://console.rejoicesolutions.in/api_console_rejoice_solutions';
// export const API_URL = 'https://rejoicebilldesk.in/api_console_rejoice_solutions';
export const API_SIGN = 'hvCm0N8ZN8lg1YQ';
export const SIGNIN = '/user_management/sign_in.php';
export const REGISTER = '/user_management/create_workspace.php';
export const CHK_USER = '/user_management/check_username.php';

// Company
export const FETCH_COMPANY = '/company/company_management/fetch_company.php';
export const ADD_COMPANY = '/company/company_management/add_company.php';
export const SPECIFIC_COMPANY = '/company/company_management/fetch_specific_company.php';

// Branch
export const FETCH_BRANCH = '/company/branch/fetch_branch.php';
export const SPECIFIC_BRANCH = '/company/branch/fetch_specific_branch.php';
export const ADD_BRANCH = '/company/branch/create_branch.php';

// Get Currency
export const GET_CURRENCY = '/company/branch/get_currency.php';

// Get Country
export const GET_COUNTRY = '/company/branch/fetch_country.php';

// Get State
export const GET_STATE = '/company/branch/fetch_state.php';

// Product
export const FETCH_PRODUCT = '/products/fetch_product.php';
export const LAUNCH_PRODUCT = '/resources/lib/curl_external/launch_product/curl_launch_product.php'

// Create user changed by hazari
export const ADD_USER = '/user_management/create_user.php'
export const FETCH_USER = "/user_management/fetch_users.php"
export const ALTER_USER = "/user_management/alter_user.php"
export const SPECIFIC_USER = '/user_management/fetch_specific_user.php';
// privlage prodcut fetch api
export const GRANT_PRODUCT_ACESS = '/user_management/grant_product_access.php'
export const FETCH_PRODUCTS_USER_ACESS = '/products/fetch_product.php'
export const DASHBOARD_DATA = '/user_management/dashboard.php'
// reminder 
export const CREATE_REMINDER = "/user_management/reminder/create_reminders.php"
export const FETCH_REMINDER = "/user_management/reminder/fetch_reminders.php"
export const FETCH_SPECIFIC_REMINDER = "/user_management/reminder/fetch_specific_reminder.php"
export const UPDATE_REMINDER_STATUS = "/user_management/reminder/update_reminders_status.php"
export const DELETE_REMINDER = "/user_management/reminder/delete_reminder.php"

// forget password
export const FORGET_PASSWORD = "/user_management/forgot_password/forgot_password.php"
