import { Add, ArrowBack } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { MuiTelInput } from 'mui-tel-input';
import FormAlerts from '../../components/form/FormAlerts';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import { decryptToken } from '../../components/security/securityFunctions';
import {
  ADD_BRANCH,
  API_SIGN,
  FETCH_COMPANY,
  GET_COUNTRY,
  GET_CURRENCY,
  GET_STATE,
  SPECIFIC_BRANCH,
} from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';

const AddBranch = () => {
  const [isCreate, setIsCreate] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
  const [companyId, setCompanyId] = useState(urlSearchParams.get('company_id') || null);
  const [id, setId] = useState(urlSearchParams.get('branch_id') || false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [companyOptions, setCompanyOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [mobile, setMobile] = useState('');
  const [adlMobile, setAdlMobile] = useState('');
  const mobileChange = (mobile) => {
    setMobile(mobile);
  };
  const mobileChangeAdl = (adlMobile) => {
    setAdlMobile(adlMobile);
  };

  const resetForm = () => {
    setCompanyId(null);
    setFormValues({
      name: '',
      city: '',
      pincode: '',
      address: '',
      contact: '',
      email: '',
      gst_no: '',
      number_of_decimal_places: '',
      base_currency_formal_name: '',
      // country_id: formValues?.country_id,
      country: '',
      state: '',
      adl_contact_number: '',
      adl_email: '',
    });
    setMobile('');
    setAdlMobile('');
  };

  const fetchCurrency = async () => {
    setIsLoading(true);
    const inputData = JSON.stringify({
      function_type: 'get_currency',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    apiCall(inputData, 'POST', GET_CURRENCY, 'plain/text').then((response) => {
      if (response.data.status === 1) {
        if (response.data.response.data.length > 0) {
          const formattedCurrencyList = response.data.response.data.map((currency) => ({
            id: currency.base_currency_formal_name,
            label: `${currency.currency_symbol} ${currency.base_currency_formal_name}`,
          }));
          setCurrencyOptions(formattedCurrencyList);
          setIsLoading(false);
        } else {
          console.log(response);
        }
      }
    });
  };

  const fetchCountry = async () => {
    setIsLoading(true);
    const inputData = JSON.stringify({
      function_type: 'fetch_country',
      company_id: companyId,
      limit: 'all',
      search_string: '',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    apiCall(inputData, 'POST', GET_COUNTRY, 'plain/text').then((response) => {
      const responseData = response.data;
      if (responseData.status === 1) {
        const currentOptions = [];
        responseData.response.data.country_info.forEach((element) => {
          currentOptions.push({ id: parseInt(element.country_id, 8), label: element.name });
        });
        setCountryOptions(currentOptions);
        setIsLoading(false);
      } else {
        console.log(response);
      }
    });
  };

  const fetchState = async (countryId) => {
    setIsLoading(true);
    const inputData = JSON.stringify({
      function_type: 'fetch_state',
      limit: 'all',
      search_string: '',
      country_id: countryId,
      api_signature: API_SIGN,
    });
    apiCall(inputData, 'POST', GET_STATE, 'plain/text').then((response) => {
      const responseData = response.data;
      if (responseData.status === 1) {
        // const currentStateOptions = [];
        // responseData.response.data.state_info.map((element) => {
        //   currentStateOptions.push({ id: parseInt(element.state_id, 8), label: element.state });
        // });
        const currentStateOptions = responseData.response.data.state_info.map((element) => ({
          id: element.state_id,
          label: element.state,
        }));
        setStateOptions(currentStateOptions);
        setIsLoading(false);
      } else {
        console.log(response);
      }
    });
  };

  const navigateToAddBranch = () => {
    navigate(
      '/dashboard/addBranch',
      { state: { showCancelBtn: true, allowBack: true, componentKey: new Date().getMilliseconds().toString() } },
      { replace: true }
    );
    setFormValues({
      name: '',
      city: '',
      pincode: '',
      address: '',
      contact: '',
      email: '',
      gst_no: '',
      number_of_decimal_places: '',
      base_currency_formal_name: '',
      // country_id: formValues?.country_id,
      country: '',
      state: '',
      adl_contact_number: '',
      adl_email: '',
    });
    setIsCreate(true);
    setCompanyId('');
    setMobile('');
    setAdlMobile('');
  };

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null && location.state.allowBack === true) {
      window.history.back();
    } else {
      navigate('/dashboard/branches');
    }
  };

  const fetchCompanies = () => {
    // setting the loader for it
    setShowLoader(true);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_company',
        jwt_token: userToken,
        search_string: searchString,
        limit: 'all',
        page: '',
      });
      apiCall(inputData, 'POST', FETCH_COMPANY, 'plain/text')
        .then((response) => {
          if (response.data.status === 1) {
            const currentOptions = response.data.response.data.company_info.map((company) => {
              // RETURN

              return {
                id: parseInt(company.company_id, 8),
                label: company.company_name,
              };
            });
            setCompanyOptions(response.data.response.data.company_info);
            // setRowsChange(response.data.response.data.company_info, 'company_id');
          } else {
            console.log(response);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const defprops = {
    options: companyOptions,
    getOptionLabel: (options) => options.company_name,
  };

  const handleData = (event, data) => {
    const value = event.target.value;
    if (value.length >= 3) {
      setSearchString(value === '' ? null : value);
      if (value.length === 0) {
        setSearchString(value === '' ? null : value);
      }
    } else {
      const companyIdFromData = data?.company_id;
      setCompanyId(companyIdFromData);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [searchString]);

  const fetchBranch = async () => {
    try {
      setShowLoader(true);
      const inputData = JSON.stringify({
        branch_id: id,
        function_type: 'fetch_specific_branch',
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      apiCall(inputData, 'POST', SPECIFIC_BRANCH, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setFormValues(response.data.response.data);
            setMobile(response.data.response.data.contact_number);
            setAdlMobile(response.data.response.data.adl_contact_number);
            fetchState(response.data.response.data.country_id);
            setShowLoader(false);
          } else {
            setErrorMessage(response.data.response);
          }
        })
        .catch((error) => {
          console.log('Error fetching branch:', error);
          setErrorMessage(error.message);
        });
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };
  // console.log(formValues)

  useEffect(() => {
    if (urlSearchParams.has('branch_id')) {
      setIsCreate(false);
      fetchBranch();
    }
  }, []);

  useEffect(() => { }, [formValues]);

  const buttonTitle = isCreate ? 'Add' : 'Update';
  const [isLoading, setIsLoading] = useState(false);
  // console.log(formValues.adl_contact_number)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: formValues?.branch_name,
      city: formValues?.branch_city,
      pincode: formValues?.branch_pincode,
      address: formValues?.branch_address,
      contact: formValues?.contact_number,
      email: formValues?.email,
      gst_no: formValues?.branch_gst ? formValues.branch_gst : '',
      number_of_decimal_places: formValues?.number_of_decimal_places,
      base_currency_formal_name: formValues?.base_currency_formal_name,
      // country_id: formValues?.country_id,
      country: formValues?.country_id,
      state: formValues?.state_id,
      adl_contact_number: formValues?.adl_contact_number ? formValues?.adl_contact_number : '',
      adl_email: formValues?.adl_email ? formValues?.adl_email : '',
      website: formValues?.website ? formValues?.website : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required'),
      city: Yup.string().max(255).required('City is required'),
      pincode: Yup.string().min(4, 'Pincode must be of atleast 4 characters').required('Pincode is required'),
      address: Yup.string().max(255).required('Address is required'),
      contact: Yup.string()
        .max(255)
        .required('Contact Number is required')
        .max(15, "Contact Number can't be more than 15 characters.")
        .min(12, 'Contact Number must be of atleast 12 characters.'),

      email: Yup.string().max(255).required('Email is required'),
      number_of_decimal_places: Yup.string().max(255).required('No. of decimal places is required'),
      base_currency_formal_name: Yup.string().max(255).required('Currency is required'),
      country: Yup.string().max(255).required('Country is required'),
      state: Yup.string().max(255).required('State is required'),
      adl_contact_number: Yup?.string()
        .max(15, "Contact Number can't be more than 15 characters.")
        .min(12, 'Contact Number must be of atleast 12 characters.'),
      adl_email: Yup?.string().max(255),
      submit: null,
    }),

    onSubmit: async (values, helpers) => {
      try {
        setIsLoading(true);
        // API request for add branch
        const payload = {
          currency_symbol_suffix_bool: 1,
          branch_name: values.name,
          branch_city: values.city,
          branch_pincode: values.pincode,
          branch_address: values.address,
          contact_number: values.contact,
          email: values.email,
          branch_gst: values.gst_no,
          number_of_decimal_places: values.number_of_decimal_places,
          base_currency_formal_name: values.base_currency_formal_name,
          country: values.country,
          state: values.state,
          adl_contact_number: values.adl_contact_number,
          adl_email: values.adl_email,
          website: values.website,
          function_type: isCreate ? 'add_branch' : 'alter_branch',
          api_signature: API_SIGN,
          jwt_token: userToken,
        };
        // console.log(payload)
        if (!isCreate) {
          payload.branch_id = id;
          payload.company_id = companyId;
        } else {
          payload.company_id = companyId;
        }

        const inputData = JSON.stringify(payload);
        // Make the API call
        setIsLoading(true); // set the loading state to true before making the API call
        apiCall(inputData, 'POST', ADD_BRANCH, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              // console.log(response)
              enqueueSnackbar(isCreate ? 'Branch added successfully.' : 'Branch details updated successfully.', {
                variant: 'success',
              });
              if (isCreate) {
                formik.resetForm();
              }
              resetForm();
              setMobile('');
              setErrorMessage('');
              navigate('/dashboard/branches');
            } else {
              enqueueSnackbar(response.data.response, { variant: 'error' });
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false); // set the loading state to false after the API call resolves
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false); // set the loading state to false if an error occurs
      }
    },
  });

  useEffect(() => {
    fetchCurrency();
    fetchCountry();
  }, []);

  const handleCountryChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('country', value);
    fetchState(value);
  };

  const handleStateChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('state', value);
  };
  // console.log(adlMobile)
  return (
    <>
      <Helmet>
        <title>{isCreate ? 'Add' : 'Update'} Branch | Rejoice Solutions</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title={isCreate ? 'Create Branch' : 'Update Branch'} />
                <div>
                  {location.state && location.state.showCancelBtn ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                  {!isCreate ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          navigateToAddBranch();
                        }}
                      >
                        <Add />
                        &nbsp; Add
                      </Button>
                      <Button
                        color="info"
                        variant="text"
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          navigateBack();
                        }}
                      >
                        <ArrowBack />
                        &nbsp; Back
                      </Button>
                    </>
                  ) : null}
                </div>
              </Stack>

              {/* {starting from here} */}
              {showLoader ? (
                <SkeletonLoaderSingleForm />
              ) : (
                <Box>
                  <div>
                    <Card sx={{ p: 2, margin: '15px 0px' }}>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          {isCreate ? (
                            <Autocomplete
                              {...defprops}
                              renderInput={(params) => <TextField {...params} label="Select Company" />}
                              onChange={handleData}
                            />
                          ) : (
                            <TextField
                              value={formValues?.company_name}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Company"
                              disabled
                            />
                          )}
                          {companyId ? (
                            <form noValidate onSubmit={formik.handleSubmit}>
                              <Stack spacing={3} marginTop={'20px'}>
                                <TextField
                                  variant="outlined"
                                  label="Name"
                                  name="name"
                                  error={!!(formik.touched.name && formik.errors.name)}
                                  helperText={formik.touched.name && formik.errors.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.name}
                                />
                                <TextField
                                  variant="outlined"
                                  label="City"
                                  name="city"
                                  error={!!(formik.touched.city && formik.errors.city)}
                                  helperText={formik.touched.city && formik.errors.city}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.city}
                                />
                                <TextField
                                  variant="outlined"
                                  label="Pincode"
                                  name="pincode"
                                  error={!!(formik.touched.pincode && formik.errors.pincode)}
                                  helperText={formik.touched.pincode && formik.errors.pincode}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.pincode}
                                />
                                <TextField
                                  variant="outlined"
                                  label="Address"
                                  name="address"
                                  error={!!(formik.touched.address && formik.errors.address)}
                                  helperText={formik.touched.address && formik.errors.address}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.address}
                                />
                                {/* <TextField
                                  variant="outlined"
                                  label="Contact Number"
                                  name="contact"
                                  error={!!(formik.touched.contact && formik.errors.contact)}
                                  helperText={formik.touched.contact && formik.errors.contact}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.contact}
                                /> */}
                                <MuiTelInput
                                  error={!!(formik.touched.contact && formik.errors.contact)}
                                  fullWidth
                                  helperText={formik.touched.contact && formik.errors.contact}
                                  label="Contact Number"
                                  name="contact"
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    mobileChange(e);
                                    formik.setFieldValue('contact', e.replace(/\s/g, ''));
                                    // formik.handleChange(e);
                                  }}
                                  value={mobile}
                                  type="mobile"
                                  defaultCountry="IN"
                                />
                                <MuiTelInput
                                  error={!!(formik.touched.adl_contact_number && formik.errors.adl_contact_number)}
                                  fullWidth
                                  helperText={formik.touched.adl_contact_number && formik.errors.adl_contact_number}
                                  label="Additional Contact Number"
                                  name="adl_contact_number"
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    mobileChangeAdl(e);
                                    formik.setFieldValue('adl_contact_number', e.replace(/\s/g, ''));
                                    // formik.handleChange(e);
                                  }}
                                  value={adlMobile}
                                  type="mobile"
                                  defaultCountry="IN"
                                />
                                <TextField
                                  variant="outlined"
                                  label="Email"
                                  name="email"
                                  error={!!(formik.touched.email && formik.errors.email)}
                                  helperText={formik.touched.email && formik.errors.email}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.email}
                                />
                                <TextField
                                  variant="outlined"
                                  label="Additional Email"
                                  name="adl_email"
                                  error={!!(formik.touched.adl_email && formik.errors.adl_email)}
                                  helperText={formik.touched.adl_email && formik.errors.adl_email}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.adl_email}
                                />
                                <TextField
                                  variant="outlined"
                                  label="GST NO."
                                  name="gst_no"
                                  error={!!(formik.touched.gst_no && formik.errors.gst_no)}
                                  helperText={formik.touched.gst_no && formik.errors.gst_no}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.gst_no}
                                />
                                <TextField
                                  variant="outlined"
                                  label="Website."
                                  name="website"
                                  error={!!(formik.touched.website && formik.errors.website)}
                                  helperText={formik.touched.website && formik.errors.website}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.website}
                                />

                                <TextField
                                  id="outlined-number"
                                  label="Number of Decimal Places"
                                  name="number_of_decimal_places"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={
                                    !!(
                                      formik.touched.number_of_decimal_places && formik.errors.number_of_decimal_places
                                    )
                                  }
                                  helperText={
                                    formik.touched.number_of_decimal_places && formik.errors.number_of_decimal_places
                                  }
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.number_of_decimal_places}
                                />
                                <FormControl>
                                  <InputLabel id="currency-select">Currency</InputLabel>
                                  <Select
                                    labelId="currency-select"
                                    id="demo-simple-select1"
                                    variant="outlined"
                                    label="Currency"
                                    name="base_currency_formal_name"
                                    error={
                                      !!(
                                        formik.touched.base_currency_formal_name &&
                                        Boolean(formik.errors.base_currency_formal_name)
                                      )
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue('base_currency_formal_name', e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    sx={{ marginBottom: '15px' }}
                                    value={formik.values.base_currency_formal_name || ''}
                                  >
                                    {currencyOptions.map((data) => {
                                      return (
                                        <MenuItem key={data.label} value={data.id}>
                                          {data.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <FormControl>
                                  <InputLabel id="country-select">Country</InputLabel>
                                  <Select
                                    labelId="country-select"
                                    id="demo-simple-select"
                                    variant="outlined"
                                    label="Country"
                                    name="country"
                                    error={!!(formik.touched.country && Boolean(formik.errors.country))}
                                    onChange={(e) => {
                                      handleCountryChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    sx={{ marginBottom: '15px' }}
                                    value={formik.values.country || ''}
                                  >
                                    {countryOptions.map((data) => (
                                      <MenuItem key={data.id} value={data.id}>
                                        {data.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText id="country-helper-text">
                                    {formik.touched.country && formik.errors.country}
                                  </FormHelperText>
                                </FormControl>
                                <FormControl>
                                  <InputLabel id="state-select">State</InputLabel>
                                  <Select
                                    labelId="state-select"
                                    id="demo-simple-select"
                                    variant="outlined"
                                    label="State"
                                    name="state"
                                    error={!!(formik.touched.state && Boolean(formik.errors.state))}
                                    onChange={handleStateChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    sx={{ marginBottom: '15px' }}
                                    value={formik.values.state || ''}
                                  >
                                    {stateOptions.map((data) => (
                                      <MenuItem key={data.id} value={data.id}>
                                        {data.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Stack>
                              {formik.errors.submit && (
                                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                                  {formik.errors.submit}
                                </Typography>
                              )}
                              <FormAlerts errorMessage={errorMessage} />
                              <CardActions sx={{ justifyContent: 'end', padding: '0px' }}>
                                <Button
                                  variant="contained"
                                  disabled={isLoading}
                                  type="submit"
                                  sx={{ margin: '20px 0px' }}
                                >
                                  {!isLoading ? buttonTitle : <CircularProgress color={'info'} size={20} />}
                                </Button>
                              </CardActions>
                            </form>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddBranch;
