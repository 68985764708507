import { AES, enc } from "crypto-js";

const ENCRYPTION_KEY = "key1";

export const encryptToken = (token) => {
    if (token) {
        const encryptedToken = AES.encrypt(token, ENCRYPTION_KEY).toString();
        return encryptedToken;
    }
    return undefined;
};

export const decryptToken = (encryptedToken) => {
    if (encryptedToken) {
        const decryptedToken = AES.decrypt(encryptedToken, ENCRYPTION_KEY).toString(enc.Utf8);
        return decryptedToken;
    }
    return undefined;
};

export const encryptRSPass = (RStoken) => {
    if (RStoken) {
        const encryptRSPass = AES.encrypt(JSON.stringify(RStoken), '8_r46Ww').toString();
        return encryptRSPass;
    }
    return undefined;
};