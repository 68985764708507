import { Button, Card, CardActions, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
// import dayjs from 'dayjs'; 

import { useFormik } from "formik";
import * as Yup from 'yup'
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Delete, DeleteOutline } from "@mui/icons-material";
import { API_SIGN, CREATE_REMINDER, DELETE_REMINDER, FETCH_SPECIFIC_REMINDER, UPDATE_REMINDER_STATUS } from "../../variables/api-variables";
import { decryptToken } from "../security/securityFunctions";
import { apiCall } from "../functions/apiCall";

const AddReminder = (props) => {
    const userToken = JSON.parse(
        decryptToken(Cookies.get("user_data_rejoice_solutions")) || "{}"
    ).userToken;
    const location = useLocation();

    const [isCreate, setIsCreate] = useState(true)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showIsConfirmDelete, setShowIsConfirmDelete] = useState(false);
    const [deleteConfirmedClick, setDeleteConfirmedClick] = useState(false);
    const [formValues, setFormValues] = useState({
        title: '',
        description: '',
        priority: '',
        reminder_date: '',
        reminder_time: '',
        repeat_on: ''
    });

    const fetchSpecificReminder = async () => {
        try {
            const inputData = JSON.stringify({
                reminder_id: props?.reminderId,
                function_type: "fetch_specific_reminders",
                api_signature: API_SIGN,
                jwt_token: userToken
            })
            apiCall(inputData, "POST", FETCH_SPECIFIC_REMINDER, "plain/text")
                .then((response) => {
                    setFormValues(response.data.response.data)
                }).catch(err => {
                    console.log(err)
                })

        } catch (error) {
            console.log(error)
        }
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: formValues?.title,
            description: formValues?.description,
            priority: formValues?.priority,
            reminderDate: formValues?.reminder_date ? formValues?.reminder_date : '',
            reminderTime: formValues?.reminder_time ? formValues?.reminder_time : '',
            repeaton: formValues?.repeat_on ? formValues?.repeat_on : 'never',

        },
        validationSchema: Yup.object({
            title: Yup.string().required("title is required"),
            description: Yup.string().required("Description is required"),
            reminderDate: Yup.date().required("Date is required"),
        })
        ,
        onSubmit: async (values, helper) => {
            setLoading(true) // set the loading state to true
            try {
                const payload = {
                    title: values.title,
                    description: values.description,
                    priority: values.priority,
                    reminder_date: values.reminderDate,
                    reminder_time: values.reminderTime,
                    repeat_on: values.repeaton,
                    share_with: null,
                    function_type: isCreate ? 'create_reminders' : "alter_reminders",
                    api_signature: API_SIGN,
                    jwt_token: userToken
                }
                if (!isCreate) {
                    payload.reminder_id = props?.reminderId
                }
                // code for update db 
                const inputData = JSON.stringify(payload)
                apiCall(inputData, "POST", CREATE_REMINDER, "plain/text").then((response) => {
                    if (response) {
                        if (isCreate) {
                            enqueueSnackbar("Reminder added sucessfully", { variant: "success" })
                            formik.resetForm();
                            setLoading(false)
                        }
                        else {
                            enqueueSnackbar("Reminder updated successfully", { variant: "success" })
                            setLoading(false)
                        }
                        props.reloadDisplayList();
                        props.close();
                    } else {
                        enqueueSnackbar(response, { variant: "error" })
                    }
                }).catch((err) => {
                    console.log(err)
                });

            } catch (error) {
                console.log(error)
            }
        }
    })

    useEffect(() => {
        if (props?.reminderId) {
            setIsCreate(false)
            fetchSpecificReminder()
        }
    }, [])

    const confirmDelete = () => {
        setDeleteConfirmedClick(true);
        try {
            const inputData = JSON.stringify({
                reminder_id: props?.reminderId,
                function_type: "delete_reminder",
                api_signature: API_SIGN,
                jwt_token: userToken
            })
            apiCall(inputData, "POST", DELETE_REMINDER, "plain/text")
                .then((response) => {
                    console.log(response)
                    if (response.data.status === 1) {
                        // successfully deleted
                        enqueueSnackbar("Reminder deleted.", { variant: "success" });
                        props.reloadDisplayList();
                        props.close();
                    } else {
                        enqueueSnackbar("Unable to delete reminder. Try again later.", { variant: 'error' })
                    }
                }).catch(err => {
                    console.log(err)
                })

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Stack width={'100%'} justifyContent={'center'} alignItems={'center'}>
                <Card sx={{ padding: '20px', width: '100%', minWidth: '350px' }}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                        <Typography variant="h5" fontWeight={'600'} marginBottom={'25px'}> {isCreate ? 'Add Reminder' : 'Edit Reminder'}</Typography>
                        {!isCreate && !showIsConfirmDelete ? <Button variant="text" color="error" size="small" onClick={() => {
                            setShowIsConfirmDelete(true);
                            setTimeout(() => {
                                if (!deleteConfirmedClick) {
                                    setShowIsConfirmDelete(false);
                                }
                            }, 2500)
                        }}>Delete</Button> : <IconButton onClick={() => {
                            confirmDelete();
                        }} color="error" size="medium"><Delete sx={{ fontSize: '22px' }} /></IconButton>}
                    </Stack>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <Stack spacing={2}>
                                    <>
                                        <TextField
                                            variant="outlined"
                                            label="Title"
                                            name="title"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                !!(formik.touched.title && formik.errors.title)
                                            }
                                            helperText={
                                                formik.touched.title && formik.errors.title
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            sx={{ marginBottom: "15px" }}
                                            value={formik.values.title}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="Description"
                                            name="description"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                !!(formik.touched.description && formik.errors.description)
                                            }
                                            helperText={
                                                formik.touched.description && formik.errors.description
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            sx={{ marginBottom: "15px" }}
                                            value={formik.values.description}
                                        />
                                        <FormControl>
                                            <InputLabel id="priority-select">Priority</InputLabel>
                                            <Select
                                                labelId="priority-select"
                                                id="demo-simple-select1"
                                                variant="outlined"
                                                label="Priority"
                                                name="priority"
                                                error={
                                                    !!(
                                                        formik.touched.priority &&
                                                        Boolean(formik.errors.priority)
                                                    )
                                                }
                                                onChange={(e) => {
                                                    formik.setFieldValue('priority', e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                fullWidth
                                                inputlabelprops={{
                                                    shrink: true,
                                                }}
                                                sx={{ marginBottom: '15px' }}
                                                value={formik.values.priority || ''}
                                            >
                                                <MenuItem value={1}>High</MenuItem>
                                                <MenuItem value={2}>Medium</MenuItem>
                                                <MenuItem value={3}>Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            variant="outlined"
                                            label="Reminder Date"
                                            type="date"
                                            name="reminderDate"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                !!(formik.touched.reminderDate && formik.errors.reminderDate)
                                            }
                                            helperText={
                                                formik.touched.reminderDate && formik.errors.reminderDate
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            sx={{ marginBottom: "15px" }}
                                            value={formik.values.reminderDate}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="Reminder time"
                                            type="time"
                                            name="reminderTime"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={
                                                !!(formik.touched.reminderTime && formik.errors.reminderTime)
                                            }
                                            helperText={
                                                formik.touched.reminderTime && formik.errors.reminderTime
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            sx={{ marginBottom: "15px" }}
                                            value={formik.values.reminderTime}
                                        />
                                        <FormControl>
                                            <InputLabel id="repeaton-select">Repeat Again</InputLabel>
                                            <Select
                                                labelId="repeaton-select"
                                                id="demo-simple-select1"
                                                variant="outlined"
                                                label="Repeat Again"
                                                name="repeaton"
                                                error={
                                                    !!(
                                                        formik.touched.repeaton &&
                                                        Boolean(formik.errors.repeaton)
                                                    )
                                                }
                                                onChange={(e) => {
                                                    formik.setFieldValue('repeaton', e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                fullWidth
                                                inputlabelprops={{
                                                    shrink: true,
                                                }}
                                                sx={{ marginBottom: '15px' }}
                                                value={formik.values.repeaton || ''}
                                            >
                                                <MenuItem value="never">Never</MenuItem>
                                                <MenuItem value="daily">Daily</MenuItem>
                                                <MenuItem value="weekly">Weekly</MenuItem>
                                                <MenuItem value="weekdays">Weekdays</MenuItem>
                                                <MenuItem value="weekends">Weekends</MenuItem>
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                                <MenuItem value="yearly">Yearly</MenuItem>
                                                <MenuItem value="sunday">Every Sunday </MenuItem>
                                                <MenuItem value="monday"> Every Monday </MenuItem>
                                                <MenuItem value="tuesday"> Every Tuesday</MenuItem>
                                                <MenuItem value="wednesday"> Every Wednesday </MenuItem>
                                                <MenuItem value="thursday"> Every Thursday </MenuItem>
                                                <MenuItem value="friday"> Every Friday </MenuItem>
                                                <MenuItem value="saturday"> Every Saturday</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                </Stack>
                                {/* <Button onClick={handleAdvanceBtn} sx={{ color: "primary", border: "solid" }} variant="contained">Advance</Button> */}

                                {formik.errors.submit && (
                                    <Typography
                                        color="error"
                                        sx={{ mt: 3 }}
                                        variant="body2"
                                    >
                                        {formik.errors.submit}
                                    </Typography>
                                )}
                                <CardActions sx={{ justifyContent: "end", padding: '0px' }}>
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        type="submit"
                                        sx={{ margin: '20px 0px' }}
                                    >
                                        {!loading ? (
                                            isCreate ? (
                                                "Add"
                                            ) : (
                                                "Update"
                                            )
                                        ) : (
                                            <CircularProgress color={"info"} size={20} />
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </Grid>
                    </Grid>
                </Card>
            </Stack>
        </>
    );
};

export default AddReminder;



