import { Container, Stack, Skeleton } from "@mui/material";

const SkeletonLoaderProducts = () => {
    return (
        <Container style={{ padding: 0, maxWidth: '1440px' }}>
            <Stack direction="row" spacing={2}>
                <Skeleton animation={'pulse'} height={'270px'} width={'200px'} />
                <Skeleton animation={'pulse'} height={'270px'} width={'200px'} />
                <Skeleton animation={'pulse'} height={'270px'} width={'200px'} />
            </Stack></Container>
    );
}

export default SkeletonLoaderProducts;