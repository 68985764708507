import { useEffect, useState } from 'react';
// import NavLink from 'react-router';
import { Helmet } from 'react-helmet-async';
import { redirect, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button, Link } from '@mui/material';
// hooks
import Cookies from 'js-cookie';
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import ForgotPassword from './ForgotPassword';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');

    const navigate = useNavigate();

    const navigateToRegister = () => {
        navigate('/login');
    }

    // verifying the login here
    const [moveFurther, setMoveFurther] = useState(false);

    const navigateToDashboard = () => {
        if (Cookies.get('user_data_rejoice_solutions') === undefined) {
            setMoveFurther(true);
            // redirect ahead
        } else {
            // redirect to dashboard
            navigate('/dashboard');
        }
    }


    useEffect(() => {
        navigateToDashboard();
    })

    return (
        <>
            <Helmet>
                <title> Forgot Password | Rejoice Solutions </title>
            </Helmet>

            <StyledRoot>
                <Logo
                    sx={{
                        position: 'fixed',
                        top: { xs: 0, sm: 0, md: 0 },
                        left: { xs: 16, sm: 24, md: 40 },
                    }}
                />

                {mdUp && (
                    <StyledSection style={{ paddingTop: '20px' }}>
                        <Typography variant="h4" sx={{ px: 5, mt: 12, mb: 0, color: "#646464", fontWeight: '500' }}>
                            Welcome to
                        </Typography>
                        <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 0, color: "#ff8345" }}>
                            Rejoice Solutions
                        </Typography>
                        <Typography variant='body1' sx={{ px: 5, mb: 5, color: '#009fe4', fontWeight: '400', marginTop: '10px', fontSize: '14pt' }}>
                            Explore a world of new opportunies.
                        </Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent style={{ paddingTop: '20px' }}>
                        <Typography variant="h4" marginBottom={'20px'}>
                            Forgot Password?
                        </Typography>

                        <ForgotPassword />
                        <Stack direction={'row'} justifyContent={'end'} alignItems={'start'} margin={'30px 0px'}>
                            <Typography variant="body2" sx={{ mb: 5 }}>
                                Remember your password, go back to

                            </Typography>
                            <Button onClick={() => {
                                navigateToRegister();
                            }} variant="text" sx={{ padding: '0px 0px' }} >Login</Button>
                        </Stack>
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
