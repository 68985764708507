import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Add, ArrowBack, Cancel, Edit } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHandleClick } from '../../components/list/use-handle-click';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import { API_SIGN, SPECIFIC_BRANCH } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { decryptToken } from '../../components/security/securityFunctions';

const ViewBranch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_solutions')) || '{}').userToken;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState({});
  const [id, setId] = useState(urlSearchParams.get('branch_id') || false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = useHandleClick()

  const navigateToEditBranch = (branchId, companyId) => {
    handleClick('/dashboard/addBranch', { branch_id: branchId, company_id: companyId }, { state: { showCancelBtn: true, allowBack: true } });
  };

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null && location.state.allowBack === true) {
      window.history.back();
    } else {
      navigate('/dashboard/branches');
    }
  };

  useEffect(() => {
    setId(urlSearchParams.get('branch_id'));
    fetchBranch();
  }, [id]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchBranch = async () => {
    try {
      setIsLoading(true);
      const inputData = JSON.stringify({
        branch_id: id,
        function_type: 'fetch_specific_branch',
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', SPECIFIC_BRANCH, 'plain/text')
        .then((response) => {
          console.log('response', response);
          if (response.data.status === 1) {
            setData(response.data.response.data);
            setIsLoading(false);
          } else {
            // setIsLoading(false);
            setErrorMessage(response.data.response);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          console.log('Error fetching branch:', error);
          setErrorMessage(error.message);
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>View Branch | Rejoice Solutions</title>
      </Helmet>
      <Container>
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title={'View Branch'} />
                <div>
                  {!isLoading ?
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigateToEditBranch(data.branch_id, data.company_id);
                      }}
                    >
                      <Edit />
                      &nbsp; Edit
                    </Button> : null}
                  <Button
                    color="info"
                    variant="text"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      navigateBack();
                    }}
                  >
                    <ArrowBack />
                    &nbsp; Back
                  </Button>
                </div>
              </Stack>

              {isLoading ? <Box margin={'30px 0px'}>
                <Skeleton animation={'pulse'} width={'100%'} height='50px' sx={{ margin: '10px 0px' }} />
                <Skeleton animation={'pulse'} width={'100%'} height='50px' />
                <Skeleton animation={'pulse'} width={'100%'} height='50px' sx={{ margin: '10px 0px' }} /></Box> :
                <Box>
                  <div>
                    <Card sx={{ p: 2, margin: '15px 0px' }}>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="Company"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.company_name}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Name"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.branch_name}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="City"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.branch_city}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Pincode"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.branch_pincode}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Address"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.branch_address}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Contact Number"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.contact_number}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Additional Contact"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.adl_contact_number || "_"}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Email"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.email}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Additional Email"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.adl_email || "_"}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="GST NO."
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.branch_gst}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Website"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.website || "_"}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="No. of decimal places"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.number_of_decimal_places}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Currency"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.base_currency_formal_name}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Country"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.country}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="State"
                                primaryTypographyProps={{ variant: 'caption', fontSize: 12, fontWeight: 400 }}
                                secondary={data.state}
                                secondaryTypographyProps={{ variant: 'body2', fontWeight: 600 }}
                              />
                            </ListItem>


                          </List>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                </Box>
              }


            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ViewBranch;
