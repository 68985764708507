import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  TableBody,
  TableCell,
  Grid,
  Table,
  TableRow,
  Button,
} from '@mui/material';
import { ArrowBack, Edit, FiberManualRecord } from '@mui/icons-material';
import { Navigate, useLocation } from 'react-router-dom';
import { useHandleClick } from '../../../components/list/use-handle-click';
import palette from '../../../theme/palette';

const SpecificUser = (props) => {
  console.log(props);
  // const { name, email, mobile, gender, role, dob, designation, userid } = props.userData;
  console.log(props, '----------------------------------------');
  const { name, email, mobile, gender, role, dob, designation, userid, status } = props.userData;
  // console.log(status)

  const handleClick = useHandleClick();
  const handleEdit = (userId) => {
    // const url = `/dashboard/addUser?company_id=${companyId}`;
    // navigate(url);
    // console.log(userId);
    handleClick(
      '/dashboard/addUser',
      { user_id: userId },
      { showCancelBtn: true, allowBack: true, componentKey: new Date().getMilliseconds().toString() }
    );
    // handleClick(
    //   '/dashboard/createUser',
    //   { user_id: userid },
    //   { showCancelBtn: true, allowBack: true, componentKey: new Date().getMilliseconds().toString() }
    // );
  };

  return (
    <>
      <Grid container spacing={2} justifyContent={'space-between'} alignItems={''}>
        <Grid xs={12} sm={4} item>
          <Card
            sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'aliceblue',
            }}
          >
            <CardContent sx={{ padding: '30px 10px', width: '100%', height: '100%' }}>
              <Stack height={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                {gender === 'M' ? (
                  <Avatar
                    src="/assets/images/avatars/avatar_2.jpg"
                    variant="large"
                    sx={{ height: '150px', width: '150px' }}
                  />
                ) : (
                  <Avatar
                    src="/assets/images/avatars/avatar_1.jpg"
                    variant="large"
                    sx={{ height: '150px', width: '150px' }}
                  />
                )}
                {console.log(gender)}
                <Typography
                  variant="h6"
                  fontWeight={'500'}
                  color={palette.grey[700]}
                  align={'center'}
                  padding={'20px 0px 0px 0px'}
                >
                  {console.log(typeof status)}
                  {name}{' '}
                  {status === '1' ? (
                    <FiberManualRecord sx={{ fontSize: 18, marginLeft: '4px' }} color={'success'} />
                  ) : (
                    <FiberManualRecord sx={{ fontSize: 18, marginLeft: '4px' }} color={'error'} />
                  )}
                </Typography>
                {designation !== null ? (
                  <Typography color={palette.grey[600]} align={'center'}>
                    {designation}
                  </Typography>
                ) : null}

                {role !== 'superadmin' ? (
                  <Button onClick={() => handleEdit(userid)} sx={{ margin: '8px 0px' }}>
                    <Edit sx={{ fontSize: 18 }} />
                    &nbsp;Edit
                  </Button>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={8} item>
          <Card sx={{ width: '100%', height: '100%' }}>
            <CardContent sx={{ padding: '0px' }}>
              <Box sx={{ padding: '5px 0px ', margin: '0px' }} variant="outlined">
                <Stack direction={'column'} justifyContent={'space-between'}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight={'500'} color={palette.info.main}>
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={'500'} color={palette.info.main}>
                            {name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Contact Number</TableCell>
                        <TableCell>{mobile}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Gender</TableCell>
                        <TableCell>{gender === 'M' ? 'Male' : 'Female'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>D. O. B.</TableCell>
                        <TableCell>{new Intl.DateTimeFormat('en-GB').format(new Date(dob))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>{status === '1' ? 'Active' : 'Blocked'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SpecificUser;
