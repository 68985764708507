import { Alert, AlertTitle, Container } from '@mui/material';
import { useState } from 'react';

export default function FormAlerts({ errorMessage, successMessage }) {
  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {errorMessage ? (
        <Alert
          severity="error"
          sx={{ mt: 3 }}
          variant="standard">
          <AlertTitle>{errorMessage}</AlertTitle>
        </Alert>
      ) : (
        <Container />
      )}
    </>
  );
}
