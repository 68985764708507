import { Suspense } from 'react';
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
import LoginPage from './pages/login/signin/LoginPage';
import Page404 from './pages/error_404/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import Register from './pages/login/register/Register';
import RegistrationSuccess from './pages/login/register/RegistrationSuccess';
import DashboardHome from './pages/dashboard_home/DashboardHome';
import ManageCompanies from './pages/companies/ManageCompanies';
import AddCompany from './pages/companies/AddCompany';
import ManageBranch from './pages/branches/ManageBranch';
import AddBranch from './pages/branches/AddBranch';
import CreateUser from './pages/users/AddUser';
import DisplayUser from './pages/users/DisplayUser';
import ManageUser from './pages/users/ManageUser';
import UserProfile from './pages/users/UserProfile';
import ViewBranch from './pages/branches/ViewBranch';
import LaunchProduct from './pages/products/LaunchProduct';
import ManageUserProductAccess from './pages/users/UserPrivileges/ManageUserProductAccess';
import ForgotPasswordPage from './pages/login/forgetpassword/ForgotPasswordPage'
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/register',
      element: <Register />,
    }, {
      path: '/registerationSuccessful',
      element: <Suspense fallback="loading "> <RegistrationSuccess /></Suspense>,
    },
    {
      path: "forgotpassword",
      element: <Suspense fallback="loading"><ForgotPasswordPage /></Suspense>
    },

    {
      path: '/dashboard',
      element: <Suspense fallback="loading."><DashboardLayout /></Suspense>,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: 'home', element: <DashboardHome /> },
        { path: 'companies', element: <ManageCompanies /> },
        { path: 'addCompany', element: <AddCompany /> },
        { path: 'branches', element: <ManageBranch /> },
        { path: 'addBranch', element: <AddBranch /> },
        { path: 'viewBranch', element: <ViewBranch /> },
        { path: 'products', element: <LaunchProduct /> },
        { path: "users", element: <ManageUser /> },
        { path: "addUser", element: <CreateUser /> },
        { path: "viewUser", element: <UserProfile /> },
        { path: "manageUserProducts", element: <ManageUserProductAccess /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;

}
