import CorporateFareTwoToneIcon from "@mui/icons-material/CorporateFareTwoTone";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";

const DashboardGrid = ({ companyCount, productsInUse, totalBranches }) => {
    return (
        <div>


            <Grid
                container
                spacing={1}
                justifyContent={"space-between"}
                alignItems={' '}
            >
                <Grid xs={12} sm={4} item>
                    <CardContent
                        sx={{ padding: "30px 10px", width: "100%", height: "100%", backgroundColor: "#6666ff" }}
                    >
                        <Stack
                            height={"100%"}
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <CorporateFareTwoToneIcon
                                variant="medium"
                                sx={{ height: "75px", width: "75px", fill: "white" }}

                            />

                            <Typography
                                variant="h3"
                                fontWeight={"500"}
                                // color={palette.grey[700]}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                                color={"white"}
                            >
                                {productsInUse}
                            </Typography>
                            <Typography
                                variant="h6"
                                fontWeight={"500"}
                                color={"white"}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                            >
                                Products In Use
                            </Typography>
                        </Stack>
                    </CardContent>
                </Grid>
                <Grid xs={12} sm={4} item>
                    <CardContent
                        sx={{ padding: "30px 10px", width: "100%", height: "100%", backgroundColor: "#b400ff" }}
                    >
                        <Stack
                            height={"100%"}
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <WidgetsIcon
                                // src="/assets/images/branch/company.jpg"
                                variant="large"
                                sx={{ height: "75px", width: "75px", fill: "white" }}
                            />

                            <Typography
                                variant="h3"
                                fontWeight={"500"}
                                // color={palette.grey[700]}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                                color={"white"}
                            >
                                {companyCount}
                            </Typography>
                            <Typography
                                variant="h6"
                                fontWeight={"500"}
                                color={"white"}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                            >
                                Companies
                            </Typography>
                        </Stack>
                    </CardContent>
                </Grid>
                <Grid xs={12} sm={4} item>
                    <CardContent
                        sx={{ padding: "30px 10px", width: "100%", height: "100%", backgroundColor: "#ff8345" }}
                    >
                        <Stack
                            height={"100%"}
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <StorefrontIcon
                                variant="large"
                                color={"white"}
                                sx={{ height: "75px", width: "75px", fill: "white" }}
                            />
                            <Typography
                                variant="h3"
                                fontWeight={"500"}
                                // color={palette.grey[700]}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                                color={"white"}
                            >
                                {totalBranches}
                            </Typography>
                            <Typography
                                variant="h6"
                                fontWeight={"500"}
                                color={"white"}
                                align={"center"}
                                padding={"1px 0px 0px 0px"}
                            >
                                Branches
                            </Typography>
                        </Stack>
                    </CardContent>
                </Grid>

            </Grid>
        </div >
    );
};

export default DashboardGrid;
